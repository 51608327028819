import { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import Magnifier from "react-magnifier";

const Image: React.FC<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    hasMagnifier?: boolean;
    fullScreen?: boolean;
  }
> = (props) => {
  const imgSrc = props.src ? process.env.REACT_APP_BASE_URL + props.src : "";
  return (
    <>
      {props.hasMagnifier ? (
        // @ts-ignore
        <Magnifier {...props} src={imgSrc} zoomFactor={3} />
      ) : (
        <img {...props} src={imgSrc} alt={props.src} />
      )}
    </>
  );
};

export default Image;
