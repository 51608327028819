import OrderIcon from "components/shared/Sidebar/assets/icon/OrderIcon";
import HrIcon from "components/shared/Sidebar/assets/icon/HrIcon";
import { ISideItemsT } from "./Sidebar.types";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AgentsIcon from "./assets/icon/AgentsIcon";
import CarsIcon from "./assets/icon/CarsIcon";
import CitiesIcon from "./assets/icon/CitiesIcon";
import TariffsIcon from "./assets/icon/TariffsIcon";
import SettingsIcon from "./assets/icon/SettingsIcon";
import UserIcon from "./assets/icon/UserIcon";
import CreditCard from "./assets/icon/CreditCard";
import ChatIcon from "./assets/icon/ChatIcon";
import OrderHistory from "./assets/icon/OrderHistory";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import IntegrationIcon from "./assets/icon/IntegrationIcon";

export const SidebarList: ISideItemsT[] = [
  {
    id: "dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    title: "dashboard",
    insidePaths: ["/dashboard"],
  },
  {
    id: "dashboard",
    isShow: false,
    path: "/analytics",
    icon: <AnalyticsIcon />,
    title: "analytics",
    insidePaths: ["/analytics"],
  },
  {
    id: "balance",
    // isNotAdmin: true,
    path: "/finance",
    icon: <CreditCard />,
    title: "finance",
    children: [
      {
        id: "card",
        title: "card",
        path: "/finance/card",
        insidePaths: ["/finance/card"],
        isNotAdmin: true,
      },
      {
        id: "balance",
        title: "balance",
        path: "/finance/transfer",
        insidePaths: ["/finance/transfer"],
      },
    ],
  },
  {
    id: "order",
    path: "/orders",
    icon: <OrderIcon />,
    title: "orders",
    insidePaths: ["/orders"],
  },
  {
    id: "order",
    path: "/order-history",
    icon: <OrderHistory />,
    title: "orders_history",
    insidePaths: ["/order-history"],
  },
  {
    // @ts-ignore
    id: "hr",
    path: "/hr",
    icon: <HrIcon />,
    title: "hr",
    children: [
      {
        id: "employee",
        title: "employees",
        path: "/hr/employees",
        insidePaths: [
          "/hr/employees",
          "/hr/employees/add",
          "/hr/employees/edit",
        ],
      },
      {
        id: "role",
        title: "roles",
        path: "/hr/roles",
        insidePaths: ["/hr/roles", "/hr/roles/add", "/hr/roles/edit"],
      },
      {
        id: "driver",
        title: "drivers",
        path: "/hr/drivers",
        insidePaths: ["/hr/drivers", "/hr/drivers/details"],
      },
    ],
  },
  {
    id: "agent",
    path: "/agents",
    icon: <AgentsIcon />,
    title: "agents",
    insidePaths: ["/agents", "/agents/add", "/agents/edit"],
  },
  {
    id: "user",
    path: "/users",
    icon: <UserIcon />,
    title: "users",
    insidePaths: ["/users", "/users/add", "/users/edit"],
  },
  {
    // @ts-ignore
    id: "car",
    path: "/cars",
    icon: <CarsIcon />,
    title: "cars",
    children: [
      {
        id: "brand",
        title: "brands",
        path: "/cars/brands",
        insidePaths: ["/cars/brands", "/cars/brands/add", "/cars/brands/edit"],
      },
      {
        id: "model",
        title: "models",
        path: "/cars/models",
        insidePaths: ["/cars/models", "/cars/models/add", "/cars/models/edit"],
      },
    ],
  },
  {
    id: "city",
    path: "/cities",
    icon: <CitiesIcon />,
    title: "cities",
    insidePaths: ["/cities", "/cities/add", "/cities/edit"],
  },
  {
    id: "tariff",
    path: "/tariff",
    icon: <TariffsIcon />,
    title: "tariffs",
    insidePaths: ["/tariff", "/tariff/add", "/tariff/edit"],
  },
  // {
  //   id: "_id",
  //   path: "/yandex-order",
  //   title: "Yandex Order",
  //   icon: <></>,
  // },
  {
    id: "city",
    path: "/chat",
    icon: <ChatIcon />,
    title: "chat",
    chatIcon: true,
    insidePaths: ["/chat", "/tariff/:id"],
  },
  {
    id: "city",
    path: "/news",
    icon: <NewspaperIcon />,
    title: "news",
  },
  {
    id: "place",
    title: "place",
    path: "/place",
    icon: <FmdGoodIcon />,
  },
  {
    id: "integration", // integration |integration
    title: "integration",
    path: "/integration",
    icon: <IntegrationIcon />,
  },
  {
    id: "_settings",
    path: "/settings",
    icon: <SettingsIcon />,
    title: "settings",
    children: [
      {
        id: "settingMap",
        title: "map",
        path: "/settings/map",
        insidePaths: ["/settings/map"],
      },
      // {
      //   id: "_id",
      //   title: "apps",
      //   path: "/settings/apps",
      //   insidePaths: ["/settings/apps"],
      // },
      {
        id: "activity",
        title: "driver_activity",
        path: "/settings/driver-activity",
        insidePaths: ["/settings/driver-activity"],
      },
      // {
      //   id: "bonusSettings",
      //   title: "customer",
      //   path: "settings/customer",
      //   insidePaths: ["/settings/customer"],
      // },
      // {
      //   id: "_id",
      //   title: "place",
      //   path: "settings/place",
      //   insidePaths: ["/settings/place"],
      // },
      {
        id: "station",
        title: "station",
        path: "settings/station",
        insidePaths: ["/settings/station"],
      },
      // {
      //   id: "_id",
      //   title: "search_radius",
      //   path: "settings/search-radius",
      //   insidePaths: ["/settings/search-radius"],
      // },
      {
        id: "photoControl",
        title: "photo_control",
        path: "settings/photo-control",
        insidePaths: ["/settings/photo-control"],
      },
      {
        id: "additionalFeature",
        title: "character",
        path: "settings/character",
        insidePaths: ["/settings/character"],
      },
      {
        id: "promocode",
        title: "promo_code",
        path: "settings/promo-code",
        insidePaths: ["/settings/promo-code"],
      },
      {
        id: "rateSettings",
        title: "rate_star",
        path: "settings/rate-star",
        insidePaths: ["/settings/rate-star"],
      },
      {
        id: "reason",
        title: "cancel_reasons",
        path: "settings/cancel-reasons",
        insidePaths: ["/settings/cancel-reasons"],
      },
      // {
      //   id: "_id",
      //   title: "cashback",
      //   path: "settings/cashback",
      //   insidePaths: ["/settings/cashback"],
      // },
      {
        id: "additionalFeature", // @role
        title: "functions",
        path: "settings/functions/phone-number",
        insidePaths: ["/settings/functions"],
      },
    ],
  },
];
