import { useCommonContext } from "context/CommonContext";
import { DataType, TableDataType } from "hooks/useRequest/useRequest.types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
// @ts-ignore
import audio from "../../assets/voice.mp3";
import { useTranslation } from "react-i18next";
import { IClientCall } from "pages/orders/OpenStreetMap_Order/listAction/components/table/Calls/container/OrderCalls.types";
import { useSocketContext } from "./context/useSocketContext";

export const useSocket = () => {
  const { t } = useTranslation();
  const {
    state: { newClientCall },
    actions: {
      setRender,
      // setUpdatedOrder,
      getNotifications,
      setChatId,
      // setNewOrder,
      getPhoneNumbers,
      setNewClientCall,
    },
  } = useCommonContext();
  const [socket, setSocket] = useState<any>();
  const { pathname } = useLocation();
  const makeNoice = () => {
    try {
      new Audio(audio).play();
      setTimeout(() => {
        new Audio(audio).play();
      }, 1000);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    if (process.env.REACT_APP_ORIGIN) {
      const newSocket = io(process.env.REACT_APP_ORIGIN, {
        path: "/admin-api/socket.io",
        extraHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        transports: ["polling"],
      });
      setSocket(newSocket);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket?.on("connect", () => {
        console.debug("--🌠socket-connected: Global Socket");
      });
      /* socket?.on("newOrder", (data: any) => {
        if (data) {
          setRender((prev: boolean) => !prev);
          setNewOrder(data.data);
          if (pathname === "/" || pathname === "/orders") {
            toast.success(t("orders.form.new_order_added"), { duration: 3000 });
          }
        }
      }); */
      /* socket?.on("updatedOrder", (data: any) => {
        // console.log("data?.data: ", data?.data);
        setUpdatedOrder(data?.data);
      }); */
      socket?.on("photoCheck", (data: any) => {
        if (data) {
          setRender((prev: boolean) => !prev);
          getNotifications();
          makeNoice();
        }
      });
      socket?.on("newDriver", (data: any) => {
        if (data) {
          setRender((prev: boolean) => !prev);
          getNotifications();
          makeNoice();
        }
      });
      socket?.on("newMessage", (data: any) => {
        if (data) {
          setChatId(data.data.userId ? data.data.userId : data.data.driverId);
          setRender((prev: boolean) => !prev);
        }
      });

      socket?.on("newCall", (data: DataType<TableDataType<any>>) => {
        getPhoneNumbers();
      });
      socket?.on("clientCall", (data: DataType<IClientCall>) => {
        setNewClientCall(data.data);
      });

      return () => {
        socket?.off("connect");
        // socket?.off("newOrder");
        // socket?.off("updatedOrder");
        socket?.off("photoCheck");
        socket?.off("newDriver");
        socket?.off("clientCall");
        socket?.off("newCall");
        socket?.off("newMessage");
      };
    }
  }, [socket]);
};
