import { ISettingsFeatures } from "context/useCommon";
import { useRequest } from "hooks";
import { useEffect } from "react";

const useSetLogoTitle = () => {
  const [getFeaturesClient, getFeaturesData, getFeaturesStatus] =
    useRequest<ISettingsFeatures>();
  const getFeatures = async () => {
    await getFeaturesClient.get(`settings/feature`);
  };
  useEffect(() => {
    getFeatures();
  }, []);

  useEffect(() => {
    if (getFeaturesStatus === "SUCCESS") {
      const favicon = document.getElementById("_favicon");
      const websiteTitle = document.getElementById("_title");
      const features = getFeaturesData.data;
      if (websiteTitle) {
        const title =
          features?.appTitle.charAt(0).toUpperCase() +
          features?.appTitle.slice(1);
        websiteTitle.innerText = title;
      }
      favicon?.setAttribute(
        "href",
        process.env.REACT_APP_BASE_URL + features.appLogo
      );
    }
  }, [getFeaturesStatus]);
};

export default useSetLogoTitle;
