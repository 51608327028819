export const REQUEST_STATUS = {
  initial: "INITIAL",
  success: "SUCCESS",
  failed: "FAILED",
  loading: "LOADING",
} as const;

export const ERROR_MESSAGES: any = {
  uz: {
    10002: "Ruxsat yo'q",
    10004: "Balans yetarli emas",
    13002: "Hodimlari mavjud",
    13003: "Haydovchilari mavjud",
    13004: "Buyurtmalari mavjud",
  },
  ru: {
    10002: "Нет разрешения",
    10004: "Баланс недостаточен",
    13002: "Есть сотрудники",
    13003: "Драйверы доступны",
    13004: "Заказы доступны",
  },
};
