const CollapseIcon = (props: any) => {
  return (
    <svg
      width="19"
      height="13"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H7.29265C7.84494 0 8.29265 0.447715 8.29265 1C8.29265 1.55228 7.84493 2 7.29265 2H1C0.447715 2 0 1.55228 0 1ZM0 6C0 5.44772 0.447715 5 1 5H11.87C12.4223 5 12.87 5.44772 12.87 6C12.87 6.55228 12.4223 7 11.87 7H0.999999C0.447714 7 0 6.55228 0 6ZM0 11C0 10.4477 0.447715 10 1 10H17C17.5523 10 18 10.4477 18 11C18 11.5523 17.5523 12 17 12H1C0.447716 12 0 11.5523 0 11Z"
        fill="white"
      />
    </svg>
  );
};

export default CollapseIcon;
