import { FC, useMemo, useState } from "react";
import { useRequest } from "../../../hooks/useRequest/useRequest";
import { uploadFileType } from "../../../constants/upload";
import UploadFileIcon from "./assets/UploadFileIcon";
import { UploadFileIconLoader, UploadFileStyled } from "./UploadFile.style";
import { UploadFilePropsType } from "./UploadFile.types";
import { sliceUrl } from "./utils/sliceUrl";
import { REQUEST_STATUS } from "hooks";
import Label from "components/form/Label/Label";
// .?[FORM_NAMES.mapImage]
const UploadFile: FC<UploadFilePropsType> = ({
  params,
  upload = () => {},
  name = "file",
  label = "",
  dataUrl = process.env.REACT_APP_BASE_URL + "upload",
  type = "img",
  setValue,
  className = "",
  defaultImageUrl = undefined,
  required = false,
}) => {
  const [client, , status] = useRequest();
  const [img, setImg] = useState<undefined | string>(undefined);

  useMemo(() => {
    if (!!defaultImageUrl) {
      setImg(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const fileUpload = async (e: any) => {
    try {
      const formState = new FormData();
      formState.append("file", e.target.files[0]);
      formState.append("type", uploadFileType[type]);
      const res = await client.post(dataUrl, formState);
      if (res.data) {
        if (setValue) {
          setValue(name, res.data);
        }
        upload(res.data);
        setImg(res.data);
      }
    } catch (e: any) {
      setImg(undefined);
    }
  };

  // function getDuration(file: any) {
  //   const url = URL.createObjectURL(file);

  //   return new Promise((resolve) => {
  //     const audio = document.createElement("audio");
  //     audio.muted = true;
  //     const source = document.createElement("source");
  //     source.src = url; //--> blob URL
  //     audio.preload = "metadata";
  //     audio.appendChild(source);
  //     audio.onloadedmetadata = function () {
  //       resolve(audio.duration);
  //     };
  //   });
  // }

  const renderIcon = () => {
    if (REQUEST_STATUS.loading === status) {
      return <UploadFileIconLoader />;
    } else if (type === "img") {
      return <UploadFileIcon width="17px" height="17px" />;
    } else return null;
  };

  return (
    <div>
      {label && <Label label={label + `${!!params?.ref ? "*" : ""}`} />}
      <UploadFileStyled className={className}>
        <label className="upload_file">
          <input
            {...params}
            className={"uploadFile"}
            type="file"
            onChange={fileUpload}
            accept={
              type === "img"
                ? ".svg, .png, .jpg, .jpeg, .ico, .webp"
                : type === "audio"
                ? "audio/*"
                : type === "ebook"
                ? ".pdf, .epub"
                : ""
            }
            disabled={status === REQUEST_STATUS.loading}
          />
          <div className="url">{img ? sliceUrl(img) : null}</div>
        </label>
        <div className="uploadFileIcon">{renderIcon()}</div>
      </UploadFileStyled>
    </div>
  );
};

export default UploadFile;
