import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./react-i18next";
import reportWebVitals from "./reportWebVitals";

import { CircularProgress } from "@mui/material";
import { Suspense } from "react";
import "./index.css";

const app = (
  // <React.StrictMode>
  <BrowserRouter>
    <Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <App />
    </Suspense>
  </BrowserRouter>
  // {/* </React.StrictMode> */}
);

ReactDOM.render(app, document.getElementById("root"));

reportWebVitals();
