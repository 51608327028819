import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

import EditIcon from "assets/EditIcon";

import { IEditButtonProps } from "./EditButton.types";
import { IconButtonStyled } from "./EditButton.style";

const EditButton: FC<IEditButtonProps> = ({ to, onClick }) => {
  const navigate = useNavigate();
  const editDataGoPath = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    onClick?.();
    if (to) {
      navigate(to);
    }
  };
  return (
    <div onClick={(event) => editDataGoPath(event)}>
      {/* <Link to={to} className="text-decoration-none"> */}
      <Tooltip title="Edit">
        <IconButtonStyled>
          <EditIcon />
        </IconButtonStyled>
      </Tooltip>
      {/* </Link> */}
    </div>
  );
};

export default EditButton;
