import { format } from "date-fns";
import dayjs from "dayjs";

const DateConvert = () => {
  const dateConvert = (
    date: string | number | undefined,
    timeCheck = false
  ) => {
    if (date) {
      if (timeCheck) {
        return format(new Date(date), "dd.MM.yyyy | HH:mm");
      } else {
        return format(new Date(date), "dd.MM.yyyy");
      }
    } else {
      return "";
    }
  };

  return [dateConvert];
};
/* export const secondsToDuration = (seconds: number) => {
  const duration = dayjs.duration(seconds, "seconds");
  const formattedDuration = duration.format("mm:ss");
  return formattedDuration;
}; */

export default DateConvert;
