import { SyntheticEvent } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { Error } from "components";

import { MultiSelectStyled } from "./MultiSelectStyled";
import { get } from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export type IMultiSelectOption = { [key: string]: any } & {
  title: string;
  _id: string;
};
interface IMultiSelect<TFormValues extends FieldValues> {
  options: IMultiSelectOption[];
  // !this is required
  control?: Control<TFormValues>;
  name?: FieldPath<TFormValues>;
  // register: /* (props: UseFormRegister<TFormType>) => */ UseFormRegisterReturn;
  rules?: Omit<
    RegisterOptions<TFormValues>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  label?: string;
  placeholder?: string;
  isControlled?: boolean;
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    values: IMultiSelectOption & any
  ) => any;
  value?: IMultiSelectOption[];
}

function MultiSelect<TFormValues extends FieldValues>({
  options = [],
  control,
  name,
  rules,
  label = "items",
  placeholder = "enter items",
  isControlled = true,
  value,
  onChange,
}: IMultiSelect<TFormValues>) {
  return isControlled && name ? (
    <Controller
      control={control}
      // @ts-ignore
      defaultValue={[]}
      rules={rules}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <MultiSelectStyled>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={options}
              disableCloseOnSelect
              onChange={(event, values) => field.onChange(values)}
              value={field.value}
              getOptionLabel={(option) => get(options, "title", "") || ""}
              renderOption={(props, option, { selected }) => {
                // console.log(field.value);
                // console.log(options);

                // const isSelected = !!options.find((option) =>
                //   !!field.value?.filter(
                //     (selectedOption: IOption) =>
                //       selectedOption?._id === option._id
                //   )
                // );

                // console.log(isSelected);

                return (
                  <li
                    {...props}
                    // onClick={isSelected ? () => {} : props.onClick}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.title}
                  </li>
                );
              }}
              // style={{ width: 500 }}
              fullWidth
              renderInput={(params) => (
                <>
                  {label && <br />}
                  <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    color={!!fieldState?.error?.message ? "error" : "success"}
                  />
                  <>
                    {fieldState?.error?.message && (
                      <Error
                        message={fieldState?.error?.message || "error in field"}
                      />
                    )}
                  </>
                </>
              )}
            />
          </MultiSelectStyled>
        );
      }}
    />
  ) : (
    <MultiSelectStyled>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={options}
        disableCloseOnSelect
        onChange={(event, values) => onChange?.(event, values)}
        value={value}
        getOptionLabel={(option) => option?.title || ""}
        renderOption={(props, option, { selected }) => {
          // console.log(field.value);
          // console.log(options);

          // const isSelected = !!options.find((option) =>
          //   !!field.value?.filter(
          //     (selectedOption: IOption) =>
          //       selectedOption?._id === option._id
          //   )
          // );

          // console.log(isSelected);

          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          );
        }}
        fullWidth
        renderInput={(params) => (
          <>
            {label && <br />}
            <TextField {...params} label={label} placeholder={placeholder} />
          </>
        )}
      />
    </MultiSelectStyled>
  );
}

export default MultiSelect;
// const top100Films = [
//   { title: "The Shawshank Redemption", year: 1994, isGood: false },
//   { title: "The Godfather", year: 1972 },
//   { title: "The Godfather: Part II", year: 1974 },
//   { title: "The Dark Knight", year: 2008 },
//   { title: "12 Angry Men", year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: "Pulp Fiction", year: 1994 },
//   {
//     title: "The Lord of the Rings: The Return of the King",
//     year: 2003,
//   },
//   { title: "The Good, the Bad and the Ugly", year: 1966 },
//   { title: "Fight Club", year: 1999 },
//   {
//     title: "The Lord of the Rings: The Fellowship of the Ring",
//     year: 2001,
//   },
//   {
//     title: "Star Wars: Episode V - The Empire Strikes Back",
//     year: 1980,
//   },
//   { title: "Forrest Gump", year: 1994 },
//   { title: "Inception", year: 2010 },
//   {
//     title: "The Lord of the Rings: The Two Towers",
//     year: 2002,
//   },
//   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { title: "Goodfellas", year: 1990 },
//   { title: "The Matrix", year: 1999 },
//   { title: "Seven Samurai", year: 1954 },
//   {
//     title: "Star Wars: Episode IV - A New Hope",
//     year: 1977,
//   },
//   { title: "City of God", year: 2002 },
//   { title: "Se7en", year: 1995 },
//   { title: "The Silence of the Lambs", year: 1991 },
//   { title: "It's a Wonderful Life", year: 1946 },
//   { title: "Life Is Beautiful", year: 1997 },
//   { title: "The Usual Suspects", year: 1995 },
//   { title: "Léon: The Professional", year: 1994 },
//   { title: "Spirited Away", year: 2001 },
//   { title: "Saving Private Ryan", year: 1998 },
//   { title: "Once Upon a Time in the West", year: 1968 },
//   { title: "American History X", year: 1998 },
//   { title: "Interstellar", year: 2014 },
// ];
