import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, TextField } from "@mui/material";
// @ts-ignore
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
// @ts-ignore
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Clear } from "@mui/icons-material";

import Label from "../Label/Label";
import Error from "../Error/Error";
import { StyledTimePicker } from "./TimePicker.style";

// interface ITimePicker {
//   label?: string;
//   variant?: "outlined" | "standard" | "filled" | undefined;
//   error?: object | string;
//   onChange: (value: any) => void;
// }

const TimePicker: FC<any> = ({ label, error, ...props }) => {
  const { t } = useTranslation();

  const renderLabel = (label: any) => <Label {...{ label }} />;

  const onClear = () => {
    if (props?.onChange) {
      props?.onChange(null);
    }
  };

  const renderTimeInput = () => (
    <StyledTimePicker>
      <DateTimePicker
        ampm={false}
        renderInput={(params: any) => (
          <div style={{ position: "relative", display: "inline-block" }}>
            <TextField
              variant={props?.variant || "outlined"}
              {...params}
              color={!!error ? "error" : undefined}
            />
            {props.value && (
              <IconButton
                style={{
                  position: "absolute",
                  top: ".5rem",
                  margin: "auto",
                  right: "2rem",
                }}
                onClick={() => onClear()}
              >
                <Clear />
              </IconButton>
            )}
          </div>
        )}
        {...props}
      />
    </StyledTimePicker>
  );

  const renderError = (error: any) => (
    <Error message={error?.message || t("error_message.required_line")} />
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && renderLabel(label)}
      {renderTimeInput()}
      {!!error && renderError(error)}
    </LocalizationProvider>
  );
};

export default TimePicker;
