import { Control, Controller, FieldPath, get } from "react-hook-form";

import Switch from "../Switch/Switch";
import React from "react";

const ControlledSwitch = <FormNames extends Record<string, any>>({
  control,
  name,
  rules,
  ...props
}: {
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  rules?: any;
  label?: React.ReactNode;
  onChange?: any
}) => {

  const switchChangeHandler = (formChangeHandler: any) => (event: any) => {
    formChangeHandler(event?.target?.checked);
    const { onChange } = props;
    if (onChange) {
      onChange(event, event?.target?.checked);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => {
        return (
          <Switch
            id={name}
            {...props}
            {...field}
            // @ts-ignore
            value={field?.value || props?.value || false}
            onChange={switchChangeHandler(field.onChange)}
            isRequired={
              typeof get(rules, "required", false) === "boolean"
                ? get(rules, "required", false)
                : get(rules, "required.value", false)
            }
            error={error}
          />
        );
      }}
    />
  );
};

export default ControlledSwitch;
