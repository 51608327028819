import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import {
  Route,
  Routes as Switch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import "styles/index.scss";
import { LightTheme } from "styles/theme/lightTheme";

import CommonProvider from "context/CommonProvider";
import Login from "pages/login/container";
import ProjectRoutes from "routes/container/Routes";
import { hasToken } from "routes/utils/hasToken/hasToken";
import useSetLogoTitle from "services/useSetLogoTitle";
import useAppVersion from "services/useAppVersion";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  useSetLogoTitle();
  useAppVersion();

  useEffect(() => {
    if (location.pathname === "/login") {
      if (hasToken()) {
        navigate("/");
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <CommonProvider>
        <ThemeProvider theme={LightTheme}>
          <Switch>
            <Route path="*" element={<ProjectRoutes />} />
            <Route path="/login" element={<Login />} />
          </Switch>
          <Toaster containerStyle={{ zIndex: "100000" }} />
        </ThemeProvider>
      </CommonProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
export default App;
