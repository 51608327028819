import { useTranslation } from "react-i18next";
import {
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";

import isAdmin from "services/roleAdmin";
import RoleManager from "services/roleManagement";

import { useCommonContext } from "context/CommonContext";
import dayjs from "dayjs";
import "react-pro-sidebar/dist/css/styles.css";
import { SidebarList } from "./SideItems";
import { MenuStyled, SidebarStyle } from "./Sidebar.style";
import { IPropsT } from "./Sidebar.types";
import Burger from "./assets/icon/CollapseIcon";
import Logo from "./assets/icon/Logo";
import { isActive } from "./utils/isActive";
import { isSubMenuActive } from "./utils/isSubMenuActive/isSubMenuActive";
import browserStorage from "services/storage/browserStorage";

const Sidebar = ({ collapse, setCollapse, newChatLength }: IPropsT) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasAccess] = RoleManager();
  const {
    state: {
      getFeaturesState: {
        getFeaturesData: {
          data: { appTitle },
        },
      },
    },
  } = useCommonContext();

  return (
    <SidebarStyle>
      <ProSidebar collapsed={collapse} breakPoint="md">
        <SidebarHeader>
          <div className="sidebar-header">
            <div className="d-flex align-items-center justify-content-between">
              {!collapse && <Logo />}
              <div
                className={`burger ${collapse ? "rotate-burger" : ""}`}
                onClick={() => setCollapse(!collapse)}
              >
                <Burger />
              </div>
            </div>
          </div>
        </SidebarHeader>

        <SidebarContent>
          <MenuStyled iconShape="circle">
            {/* <MenuItem
              className={`  ${
                isActive(["/dashboard"], location.pathname) ? "active" : ""
              } `}
              icon={<Dashboard />}
              onClick={(e) => {
                e.preventDefault();
                if (location.pathname !== "/dashboard") {
                  navigate("/dashboard");
                }
              }}
            >
              {t(`sidebar.dashboard`)}
            </MenuItem> */}
            {SidebarList.map((item) => {
              return item.children
                ? (item?.isShow ? item?.isShow : true) &&
                    !(isAdmin && item?.isNotAdmin) &&
                    item?.children?.find((ch: any) => hasAccess(ch.id)) && (
                      // @ts-ignore
                      <SubMenu
                        key={item.path}
                        title={t(`sidebar.${item.title}`)}
                        icon={item.icon}
                        className={`  ${
                          isSubMenuActive(
                            item.children,
                            location.pathname,
                            collapse
                          )
                            ? "active"
                            : ""
                        } `}
                      >
                        {item.children.map((children) => {
                          if (
                            hasAccess(children.id) &&
                            !(isAdmin && children.isNotAdmin)
                          ) {
                            return (
                              <MenuItem
                                key={children.path}
                                className={`  ${
                                  isActive(
                                    children.insidePaths,
                                    location.pathname
                                  )
                                    ? "active"
                                    : ""
                                } `}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (location.pathname !== children.path) {
                                    navigate(children.path);
                                  }
                                }}
                              >
                                {t(`sidebar.${children.title}`)}
                              </MenuItem>
                            );
                          }
                        })}
                      </SubMenu>
                    )
                : (item?.isShow ? item?.isShow : true) &&
                    !(isAdmin && item?.isNotAdmin) &&
                    hasAccess(item.id) && (
                      <MenuItem
                        key={item.path}
                        className={` ${
                          isActive(item.insidePaths, location.pathname)
                            ? "active"
                            : ""
                        }`}
                        icon={item.icon}
                        onClick={(e) => {
                          e.preventDefault();
                          if (location.pathname !== item.path) {
                            navigate(item.path);
                          }
                        }}
                      >
                        {t([`sidebar.${item.title}`])}
                        {item.chatIcon && newChatLength > 0 && (
                          <div className="new-chat-length">{newChatLength}</div>
                        )}
                      </MenuItem>
                    );
            })}
          </MenuStyled>
        </SidebarContent>

        <SidebarFooter className="sidebar-footer py-3">
          <span>
            {!collapse &&
              appTitle +
                ` © ${dayjs().year()} ` +
                `v${browserStorage.get("appVersion") || 0}`}
          </span>
        </SidebarFooter>
      </ProSidebar>
    </SidebarStyle>
  );
};

export default Sidebar;
