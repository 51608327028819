import { FC, useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { debounce } from "services/debounce/debounce";
import { AutoComplateStyled } from "./SelectLocation.style";
import Label from "../Label/Label";
import { Error } from "../../index";
import { Controller } from "react-hook-form";
import { ISelectLocationT } from "./SelectLocation.types";
import { useRequest } from "hooks";
import get from "lodash.get";

const SelectLocation: FC<ISelectLocationT> = ({
  label = "",
  phoneNumber = "",
  error,
  name = "",
  className = "",
  control,
  rules = { required: false },
  defaultValue,
  dataKey = "name",
  defaultInputValue = "",
  multiple = false,
  onChangeSelect,
  placeholder = "",
  optional,
  disabled = false,
  setFocused,
  setFocused2,
  renderOption = (props, option) => {
    return (
      <MenuItem {...props} key={option?._id}>
        {get(option, "name")}
      </MenuItem>
    );
  },
  ...props
}) => {
  const [getLocationsClient] = useRequest();
  const [options, setOptions] = useState<any>([]);

  const handleInputChange = async (e: any, search: string) => {
    const res = await getLocationsClient.get(
      `${process.env.REACT_APP_BASE_URL}/address/name?search=${
        search || ""
      }&phoneNumber=${phoneNumber || ""}`
    );
    if (res?.data) {
      setOptions(res.data);
    }
  };

  const handleInputChageByPhone = async () => {
    const res = await getLocationsClient.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/address/name?search=${""}&phoneNumber=${phoneNumber || ""}`
    );
    if (res?.data) {
      setOptions(res.data);
    }
  };

  const onInputChange = debounce(handleInputChange, 400);

  const getLocations = async () => {
    const res = await getLocationsClient.get(
      `${process.env.REACT_APP_BASE_URL}/address/name?search=tashkent`
    );
    if (res?.data) {
      setOptions(res.data);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      handleInputChageByPhone();
    }
  }, [phoneNumber]);

  const handleFocus = (name: string) => {
    if (name === "sourceLocation") {
      typeof setFocused !== "undefined" && setFocused(true);
    }
    if (name === "destinationLocations") {
      typeof setFocused2 !== "undefined" && setFocused2(true);
    }
  };

  // const handleBlur = (name: string) => {
  //   if (name === "sourceLocation") {
  //     typeof setFocused !== "undefined" && setFocused(false);
  //   }
  //   if (name === "destinationLocations") {
  //     typeof setFocused2 !== "undefined" && setFocused2(false);
  //   }
  // };

  return (
    <div className={className}>
      {label && <Label label={label} />}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...field } }) => {
          return (
            <AutoComplateStyled
              multiple={multiple}
              id="select"
              options={options}
              defaultValue={defaultValue}
              className={
                (!!error ? "error" : "") +
                (field?.value?.length > 3 && multiple
                  ? " btn-clean-bottom"
                  : "")
              }
              getOptionLabel={(option: any) => get(option, "name") || ""}
              loadingText="Loading..."
              noOptionsText="Data not found"
              disabled={disabled}
              onChange={(event: any, dataItem: any) => {
                onChange(dataItem);
                if (onChangeSelect) {
                  onChangeSelect(dataItem);
                }
              }}
              renderOption={renderOption}
              // isOptionEqualToValue={(option: any, value: any) =>
              //   option[dataKey] === value[dataKey]
              // }
              renderInput={(prop: any) => (
                <TextField
                  onFocus={() => handleFocus(name)}
                  // onBlur={() => handleBlur(name)}
                  {...prop}
                  label=""
                  placeholder={placeholder}
                  onChange={(e) =>
                    onInputChange(optional ? optional : null, e.target.value)
                  }
                />
              )}
              {...props}
              value={multiple ? field.value || [] : field.value || ""}
            />
          );
        }}
      />
      {!!error && <Error message={error?.message} />}
    </div>
  );
};

export default SelectLocation;
