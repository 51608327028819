import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Grid } from "@mui/material";

import { useRequest } from "hooks";
import Modal from "components/common/Modal/Modal";
import CancelButton from "components/form/CancelButton/CancelButton";
import Button from "components/form/SaveButton/Button";
import Input from "components/form/Input/Input";
import { useCommonContext } from "context/CommonContext";

import { FORM_NAMES } from "./ChangePassword.constants";

interface IAgentPasswordForm {
  oldPAssword: string;
  newPassword: string;
  reNewPassword: string;
}
interface IAdminPasswordEditBody {
  oldPassword: string;
  password: string;
}

const ChangePassword = ({ open, setOpen }: any) => {
  const { t } = useTranslation();
  const { handleSubmit, formState, register } = useForm<IAgentPasswordForm>();
  const {
    state: { admin },
  } = useCommonContext();

  const [notMatchedError, setNotMatchedError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);

  const [passwordClient, , passwordStatus] = useRequest<
    any,
    IAdminPasswordEditBody
  >();
  useEffect(() => {
    if (passwordStatus === "SUCCESS") {
      setOpen(false);
    }
  }, [passwordStatus]);

  const submit = (formData: IAgentPasswordForm) => {
    if (formData.newPassword !== formData.reNewPassword) {
      setNotMatchedError(true);
      return;
    } else setNotMatchedError(false);
    if (admin.password !== formData.oldPAssword) {
      setOldPasswordError(true);
      return;
    } else setOldPasswordError(false);

    passwordClient.post("password", {
      password: formData.reNewPassword,
      oldPassword: formData.oldPAssword,
    });
  };

  return (
    <Modal
      {...{ open, closeModal: setOpen }}
      title={t("navbar.profile.change_password.modal.title")}
    >
      <form onSubmit={handleSubmit(submit)}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Input
              label={t(
                "navbar.profile.change_password.modal.form.old_password"
              )}
              params={{
                ...register(FORM_NAMES.oldPAssword, {
                  required: {
                    value: true,
                    message: t("error_message.required_line"),
                  },
                }),
                type: "password",
                minLength: {
                  value: 6,
                  message: t(
                    "error_message.length_of_password_must_be_more_that_6_chars"
                  ),
                },
              }}
              customErrorMessage={
                oldPasswordError ? "Old password is incorrect!" : ""
              }
              error={formState.errors[FORM_NAMES.oldPAssword]}
            />
          </Grid>
          <Grid item sm={12}>
            <Input
              label={t(
                "navbar.profile.change_password.modal.form.new_password"
              )}
              params={{
                ...register(FORM_NAMES.newPassword, {
                  required: {
                    value: true,
                    message: t("error_message.required_line"),
                  },
                }),
                type: "password",
                minLength: {
                  value: 6,
                  message: t(
                    "error_message.length_of_password_must_be_more_that_6_chars"
                  ),
                },
              }}
              error={formState.errors[FORM_NAMES.newPassword]}
            />
          </Grid>
          <Grid item sm={12}>
            <Input
              label={t(
                "navbar.profile.change_password.modal.form.re_new_password"
              )}
              params={{
                ...register(FORM_NAMES.reNewPassword, {
                  required: {
                    value: true,
                    message: t("error_message.required_line"),
                  },
                  minLength: {
                    value: 6,
                    message: t(
                      "error_message.length_of_password_must_be_more_that_6_chars"
                    ),
                  },
                }),
                type: "password",
              }}
              customErrorMessage={
                notMatchedError ? "Is not matching with new password!" : ""
              }
              error={formState.errors[FORM_NAMES.reNewPassword]}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          className="pt-4 pb-2"
        >
          <Grid item>
            <CancelButton type="button" onClick={() => setOpen(false)} />
          </Grid>
          <Grid item className="ms-3">
            <Button type="submit" loading={passwordStatus} />
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default ChangePassword;
