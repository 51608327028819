import { Menu } from "react-pro-sidebar";
import styled from "styled-components";

export const SidebarStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.whiteBg} !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100% !important;
  z-index: 1001;
  .sidebar-header {
    padding: 18px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    height: 70px;
    background: ${({ theme }) => theme.colors.bg.blue};
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .burger {
      cursor: pointer;
      margin-left: 80px;
      margin-top: 8px !important;
    }
    .rotate-burger {
      transform: rotateY(180deg);
      margin: 0 auto;
    }
  }
  .sidebar-footer {
    text-align: center;
    text-transform: capitalize;
  }
  .pro-sidebar-inner {
    transition: all 0.3s linear !important;
    background: #fff !important;
    min-width: 100%;
    width: 100%;
    .pro-sidebar-layout {
      overflow: hidden !important;
      .pro-sidebar-content {
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          width: 3px !important;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: ${({ theme }) => theme.colors.bg.blue};
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          opacity: 0.7;
        }
      }
    }
  }
`;

export const MenuStyled = styled(Menu)`
  padding: 0 !important;
  ul {
    .pro-menu-item {
      .pro-inner-item {
        &:hover {
          background-color: ${({ theme }) => theme.colors.bg.mainLightBg};
        }
        .pro-icon-wrapper {
          background-color: transparent !important;
        }
        .pro-item-content {
          color: ${({ theme }) => theme.colors.color.lightBlack} !important;
        }
      }
    }
    .pro-menu-item.pro-sub-menu {
      .react-slidedown.pro-inner-list-item {
        background-color: transparent !important;
      }
      .pro-inner-list-item {
        .popper-inner {
          background-color: ${({ theme }) =>
            theme.colors.bg.whiteBg} !important;
          box-shadow: 0px 5px 15px 0px rgba(40, 40, 40, 0.1);
        }
      }
    }
  }
  .new-chat-length {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #339c33;
    color: #fff;
    float: right;
    padding: 1px 4px;
    border-radius: 10px;
    font-size: 11px;
  }
`;
