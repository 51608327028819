import { lazy } from "react";

import { IRoute } from "../Routes.types";
import { Navigate } from "react-router-dom";
// const YandexOrder = lazy(() => import("pages/orders-with-yandex"));
const Analytics = lazy(() => import("pages/analytics"));
const Dashboard = lazy(() => import("pages/dashboard/container"));
const CardsList = lazy(() => import("pages/finance/card"));
const Transfer = lazy(() => import("pages/finance/transfer"));
const RoleRoutes = lazy(() => import("pages/hr/role"));
const Employees = lazy(() => import("pages/hr/employees"));
const Drivers = lazy(() => import("pages/hr/driver"));
const Agents = lazy(() => import("pages/agents"));
const AgentDetails = lazy(
  () => import("pages/agents/Details/container/AgentDetails")
);
const Users = lazy(() => import("pages/users"));
const Brands = lazy(() => import("pages/cars/brands"));
const Models = lazy(() => import("pages/cars/models"));
const Cities = lazy(() => import("pages/cities"));
const Orders = lazy(() => import("pages/orders"));
const OrdersHistory = lazy(() => import("pages/ordersHistory"));
const Tariffs = lazy(() => import("pages/tariffs"));
const Chat = lazy(() => import("pages/chat"));
const News = lazy(() => import("pages/news"));
const Integration = lazy(() => import("pages/integration"));
const Map = lazy(() => import("pages/settings/map/container"));
// const Customer = lazy(() => import("pages/settings/customer/container"));
const Place = lazy(() => import("pages/place"));
const Station = lazy(() => import("pages/settings/station"));
const SearchRadius = lazy(
  () => import("pages/settings/searchRadius/container")
);
const PhotoControl = lazy(
  () => import("pages/settings/photoControl/container")
);
const Character = lazy(() => import("pages/settings/character/container"));
const PromoCode = lazy(() => import("pages/settings/promoCode/container"));
const CancelReason = lazy(
  () => import("pages/settings/cancelReason/container")
);
const RateStar = lazy(() => import("pages/settings/rateStar/container"));
const SettingsFunction = lazy(
  () => import("pages/settings/function/container")
);
const DriverActivity = lazy(
  () => import("pages/settings/DriverActivity/container")
);
const LeafletMapsPlaces = lazy(
  () => import("pages/settings/_LeafletMapsPlaces")
);
const NotFound = lazy(() => import("pages/notFound/container"));
const MoveHistory = lazy(() => import("pages/moveHistory"));
const AgentNotFound = lazy(
  () => import("pages/notFound/components/AgentNotFound/AgentNotFound")
);

export const routes: IRoute[] = [
  {
    key: "dashboard",
    name: "dashboard",
    isPrivate: true,
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: "order",
    name: "orders",
    isPrivate: true,
    path: "/",
    element: <Navigate to="orders" />,
  },
  {
    key: "dashboard",
    name: "analytics",
    isPrivate: true,
    path: "analytics/*",
    element: <Analytics />,
  },

  {
    key: "card",
    name: "card",
    isPrivate: true,
    path: "finance/card/*",
    element: <CardsList />,
  },
  {
    key: "balance",
    name: "transfer",
    isPrivate: true,
    path: "finance/transfer/*",
    element: <Transfer />,
  },
  {
    key: "order",
    name: "orders",
    isPrivate: true,
    path: "orders/*",
    element: <Orders />,
  },
  {
    key: "order",
    name: "orderHistory",
    isPrivate: true,
    path: "order-history/*",
    element: <OrdersHistory />,
  },
  {
    key: "role",
    name: "role",
    isPrivate: true,
    path: "hr/roles/*",
    element: <RoleRoutes />,
  },
  {
    key: "employee",
    name: "employees",
    isPrivate: true,
    path: "hr/employees/*",
    element: <Employees />,
  },
  {
    key: "driver",
    name: "drivers",
    isPrivate: true,
    path: "hr/drivers/*",
    element: <Drivers />,
  },
  {
    key: "agent",
    name: "agents",
    isPrivate: true,
    path: "agents/*",
    element: <Agents />,
  },
  {
    key: "_id",
    name: "agentInfo",
    isPrivate: true,
    path: "agents/details/:id",
    element: <AgentDetails />,
  },
  {
    key: "user",
    name: "users",
    isPrivate: true,
    path: "users/*",
    element: <Users />,
  },
  {
    key: "brand",
    name: "brands",
    isPrivate: true,
    path: "cars/brands/*",
    element: <Brands />,
  },
  {
    key: "model",
    name: "model",
    isPrivate: true,
    path: "cars/models/*",
    element: <Models />,
  },
  {
    key: "city",
    name: "cities",
    isPrivate: true,
    path: "cities/*",
    element: <Cities />,
  },
  {
    key: "tariff",
    name: "tariff",
    isPrivate: true,
    path: "tariff/*",
    element: <Tariffs />,
  },
  {
    key: "city",
    name: "chat",
    isPrivate: true,
    path: "chat/*",
    element: <Chat />,
  },
  {
    key: "city",
    name: "news",
    isPrivate: true,
    path: "news/*",
    element: <News />,
  },
  {
    key: "_id",
    name: "Integrations",
    isPrivate: true,
    path: "integration/*",
    element: <Integration />,
  },
  {
    key: "place",
    name: "place",
    isPrivate: true,
    path: "place/*",
    element: <Place />,
  },
  {
    key: "_id",
    name: "map",
    isPrivate: true,
    path: "settings/map",
    element: <Map />,
  },
  /* {
    key: "_id",
    name: "customer",
    isPrivate: true,
    path: "settings/customer",
    element: <Customer />,
  }, */
  {
    key: "_id",
    name: "station",
    isPrivate: true,
    path: "settings/station/*",
    element: <Station />,
  },
  {
    key: "_id",
    name: "searchRadius",
    isPrivate: true,
    path: "settings/search-radius/*",
    element: <SearchRadius />,
  },
  {
    key: "_id",
    name: "photoControl",
    isPrivate: true,
    path: "settings/photo-control/*",
    element: <PhotoControl />,
  },
  {
    key: "_id",
    name: "character",
    isPrivate: true,
    path: "settings/character/*",
    element: <Character />,
  },
  {
    key: "_id",
    name: "promoCode",
    isPrivate: true,
    path: "settings/promo-code/*",
    element: <PromoCode />,
  },
  // {
  //   key: "_id",
  //   name: "phone-number",
  //   isPrivate: true,
  //   path: "settings/phone-number/*",
  //   element: <PhoneNumbersSetting />,
  // },
  {
    key: "_id",
    name: "functions",
    isPrivate: true,
    path: "settings/functions/*",
    element: <SettingsFunction />,
  },
  {
    key: "_id",
    name: "rateStar",
    isPrivate: true,
    path: "settings/rate-star/*",
    element: <RateStar />,
  },
  {
    key: "_id",
    name: "cancel-reason",
    isPrivate: true,
    path: "settings/cancel-reasons/*",
    element: <CancelReason />,
  },
  {
    key: "_id",
    name: "driver-activity",
    isPrivate: true,
    path: "settings/driver-activity/*",
    element: <DriverActivity />,
  },
  // {
  //   key: "_id",
  //   name: "functions",
  //   isPrivate: true,
  //   path: "settings/functions/*",
  //   element: <Functions />,
  // },
  {
    key: "_id",
    name: "functions",
    isPrivate: true,
    path: "settings/functions/*",
    element: <SettingsFunction />,
  },
  {
    key: "_id",
    name: "functions",
    isPrivate: true,
    path: "leaflet-map-data/*",
    element: <LeafletMapsPlaces />,
  },
  // {
  //   key: "_id",
  //   name: "yandex-order",
  //   isPrivate: true,
  //   path: "yandex-order/*",
  //   element: <YandexOrder />,
  // },
  {
    key: "notFound",
    name: "notFound",
    isPrivate: false,
    path: "*",
    element: <NotFound />,
  },
  {
    key: "role",
    name: "role",
    isPrivate: true,
    path: "movement-history/*",
    element: <MoveHistory />,
  },
  /* {
    key: "notFound",
    name: "agentNotFound",
    isPrivate: false,
    path: "agent-not-found",
    element: <AgentNotFound />,
  }, */
];
