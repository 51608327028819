import Profile from "./Profile";

const index = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default index;
