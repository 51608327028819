import { Controller } from "react-hook-form";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TimePickerFormStyled } from "./TimePickerForm.style";
import Label from "../Label/Label";

dayjs.extend(customParseFormat);

type TimePickerProps = {
  control: any;
  name: string;
  label?: string;
};

const TimePickerForm: React.FC<TimePickerProps> = ({
  control,
  name,
  label,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field }) => {
        return (
          <TimePickerFormStyled>
            {label && <Label label={label} />}
            <TimePicker
              {...field}
              format="HH:mm"
              defaultValue={dayjs()}
              value={field.value ? dayjs(field.value) : null}
              showNow={false}
              placeholder={dayjs().format("HH:mm")}
              onChange={(value) => field.onChange(value ? value : null)}
            />
          </TimePickerFormStyled>
        );
      }}
    />
  );
};

export default TimePickerForm;
