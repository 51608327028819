import { useRequest } from "hooks";
import { useEffect } from "react";
import browserStorage from "services/storage/browserStorage";
interface IGetVersionResponse {
  appVersion: number;
  backendVersion: number;
}
const useAppVersion = () => {
  const LOCAL_APP_VERSION_KEY = "appVersion";
  const [getAppVersionClient, getAppVersionData, getAppVersionStatus] =
    useRequest<IGetVersionResponse>();
  const getAppVersion = async () => {
    await getAppVersionClient.get(`version`);
  };
  useEffect(() => {
    getAppVersion();
  }, []);
  useEffect(() => {
    if (getAppVersionStatus === "SUCCESS") {
      checkVersion();
    }
  }, [getAppVersionStatus]);

  const checkVersion = () => {
    const LOCAL_APP_VERSION: string | undefined = browserStorage.get(
      LOCAL_APP_VERSION_KEY
    );
    const CURRENT_APP_VERSION = +getAppVersionData.data.appVersion;
    if (LOCAL_APP_VERSION) {
      if (Number(LOCAL_APP_VERSION) !== CURRENT_APP_VERSION) {
        browserStorage.set(LOCAL_APP_VERSION_KEY, CURRENT_APP_VERSION);
        // @ts-ignore
        window.location.reload(true);
      }
    } else {
      browserStorage.set(LOCAL_APP_VERSION_KEY, CURRENT_APP_VERSION);
    }
  };
};

export default useAppVersion;
