import { FC, useContext } from "react";
import { MenuItem, TextField } from "@mui/material";
import { debounce } from "services/debounce/debounce";
import { AutoComplateStyled } from "./Select.style";
import { ISelectProps } from "./Select.types";
import Label from "../Label/Label";
import { Error } from "../../index";
import { Controller } from "react-hook-form";
import currencyFormatter from "services/number_format/currencyFormatter";
import { CommonContext } from "context";
import { RUseCommon } from "context/useCommon";
import { useRequest } from "hooks";

const Select = <FormNames extends Record<string, any>>({
  label = "",
  options = [],
  // optionsUrl,
  error,
  name,
  className = "",
  handleInputChange,
  control,
  rules = { required: false },
  defaultValue,
  valueProp = "_id",
  dataKey = "name",
  dataKey2,
  dataKey3,
  defaultInputValue = "",
  multiple = false,
  onChangeSelect,
  placeholder = "",
  optional,
  disabled = false,
  onBlur,
  filterOptions,
  ...props
}: ISelectProps<FormNames>) => {
  const onInputChange = debounce(handleInputChange, 400);

  const {
    state: {
      getDefaultPhoneState: { getDefaultPhoneData },
    },
  } = useContext<RUseCommon>(CommonContext);

  return (
    <div className={className}>
      {label && <Label label={label} />}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, ...field } }) => {
          return (
            <AutoComplateStyled
              multiple={multiple}
              id="select"
              options={options}
              defaultValue={defaultValue}
              className={!!error ? "error" : ""}
              getOptionLabel={(option: any) =>
                option[dataKey]
                  ? `${option[dataKey] ? option[dataKey] : ""} ${
                      dataKey2 ? option[dataKey2] : ""
                    } ${
                      dataKey3
                        ? `(${currencyFormatter(option[dataKey3])} ${
                            getDefaultPhoneData.data?.currency
                          })`
                        : ""
                    }`
                  : ""
              }
              loadingText="Loading..."
              noOptionsText="Data not found"
              disabled={disabled}
              filterOptions={filterOptions}
              onChange={(event: any, dataItem: any) => {
                if (multiple) {
                  // this logic to prevent adding the same option to select
                  const lastAddedOption = dataItem.pop();
                  const isPrevAdded = !!dataItem.find(
                    (option: any) =>
                      (option[valueProp] || option.value) ===
                      (lastAddedOption.value || lastAddedOption[valueProp])
                  );
                  if (!isPrevAdded && lastAddedOption)
                    onChange([...dataItem, lastAddedOption]);
                  else onChange(dataItem);
                } else onChange(dataItem);

                if (onChangeSelect) {
                  onChangeSelect(dataItem);
                }
              }}
              renderOption={(props: any, option: any) => (
                <MenuItem {...props} key={option?.[valueProp]}>
                  {dataKey ? option[dataKey] : ""}{" "}
                  {dataKey2 ? option[dataKey2] : ""}{" "}
                  {dataKey3
                    ? `(${currencyFormatter(option[dataKey3])} ${
                        getDefaultPhoneData.data?.currency
                      })`
                    : ""}
                </MenuItem>
              )}
              // isOptionEqualToValue={(option: any, value: any) =>
              //   option[dataKey] === value[dataKey]
              // }
              renderInput={(prop: any) => (
                <TextField
                  {...prop}
                  label=""
                  placeholder={placeholder}
                  onBlur={onBlur}
                  onChange={(e) =>
                    handleInputChange && onInputChange(e.target.value)
                  }
                />
              )}
              {...props}
              value={field.value || ""}
            />
          );
        }}
      />
      {!!error && <Error message={error?.message} />}
    </div>
  );
};

export default Select;
