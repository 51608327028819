import { useContext, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Badge from "components/shared/Badge/Badge";
import { CommonContext } from "context";
import { REQUEST_STATUS } from "hooks";
import DateConvert from "services/dateConvert/DateConvert";

import { NotificationsStyled } from "./Notifications.style";
import { RUseCommon } from "context/useCommon";

const Notifications = () => {
  const defaultLimit = 15;
  const [limit, setLimit] = useState<number>(defaultLimit);
  const [notifications, setNotifications] = useState<any>([]);
  const [dateConvert] = DateConvert();
  const navigate = useNavigate();

  const {
    state: {
      getNotificationsState: { getNotificationsData, getNotificationsStatus },
      makeReadState: { makeReadStatus },
      notificationsLength,
      notificationOpen,
    },
    actions: {
      setNotificationsLength,
      getNotifications,
      setNotificationOpen,
      makeRead,
    },
  } = useContext<RUseCommon>(CommonContext);

  const fetchMoreData = () => {
    setLimit((prev) => prev + defaultLimit);
    getNotifications(limit + defaultLimit);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    if (makeReadStatus === REQUEST_STATUS.success) {
      getNotifications();
    }
  }, [makeReadStatus]);

  useEffect(() => {
    if (getNotificationsStatus === REQUEST_STATUS.success) {
      setNotifications(getNotificationsData?.data?.data);
      setNotificationsLength(
        getNotificationsData?.data?.data?.filter((e: any) => e.isRead === false)
          .length
      );
    }
  }, [getNotificationsStatus]);

  const renderShowMoreButton = useCallback(() => {
    if (notifications?.length < getNotificationsData?.data?.total) {
      return (
        <div className="fetch-more-btn-wrapper">
          <button
            className="fetch-more-btn"
            onClick={(e) => {
              e.stopPropagation();
              fetchMoreData();
            }}
          >
            {t("common.next")}
          </button>
        </div>
      );
    } else return null;
  }, [notifications, getNotificationsStatus]);

  document.addEventListener("keydown", function (e) {
    if (e.key === "Escape") {
      setNotificationOpen(false);
    }
  });
  const { t } = useTranslation();
  return (
    <NotificationsStyled>
      <span
        onClick={(e) => {
          e.stopPropagation();
          setNotificationOpen((prev: boolean) => !prev);
        }}
        className="bell"
      >
        <Badge badgeContent={notificationsLength} />
      </span>
      {notificationOpen && (
        // <InfiniteScroll
        //   dataLength={notifications.length}
        //   next={fetchMoreData}
        //   hasMore={
        //     getNotificationsStatus === REQUEST_STATUS.success
        //       ? getNotificationsData?.data?.data?.length !==
        //         getNotificationsData?.data?.total
        //       : false
        //   }
        //   // endMessage={bookList?.length === total && <h3>You have seen it all</h3>}
        //   scrollThreshold={0.6}
        //   loader={<InfiniteScrollLoader />}
        // >
        //   <div className="wrapper">
        //     {notifications.length > 0 && <h3>Bildirishnomalar</h3>}
        //     {notifications?.map((e: any) => (
        //       <div className="item">
        //         <p>
        //           {e.type === "newDriver"
        //             ? "Yangi haydovchi"
        //             : "Yangi mashina rasmlari"}
        //           : &nbsp;
        //           {e.firstName} {e.lastName}
        //         </p>
        //         <time>{dateConvert(e.createdAt, true)}</time>
        //       </div>
        //     ))}
        //     {!notifications.length && (
        //       <p className="no-notifications">Bildirishnomalar mavjud emas</p>
        //     )}
        //   </div>
        // </InfiniteScroll>

        <div className="wrapper">
          {notifications.length > 0 && (
            <h3>{t("navbar.notification.title")}</h3>
          )}
          {notifications?.map((e: any, i: number) => (
            <div
              className="item"
              key={i}
              onClick={() => {
                navigate(`hr/drivers/details/${e.driverId}`);
                setNotificationOpen(false);
                makeRead(e._id);
              }}
            >
              {!e.isRead && <div className="isRead"></div>}
              <p>
                {e.type === "newDriver"
                  ? t("navbar.notification.new_carer")
                  : e.type === "licensePhotoCheck"
                  ? t("navbar.notification.new_mark_img")
                  : t("navbar.notification.new_car_img")}
                : &nbsp;
                {e.firstName} {e.lastName}
              </p>
              <time>{dateConvert(e.createdAt, true)}</time>
            </div>
          ))}
          {!notifications.length && (
            <p className="no-notifications">
              {t("navbar.notification.no_notification")}
            </p>
          )}
          {renderShowMoreButton()}
        </div>
      )}
    </NotificationsStyled>
  );
};

export default Notifications;
