import { FC } from "react";
import { useTranslation } from "react-i18next";

import PlusIcon from "./assets/PlusIcon";

import { CreateButtonStyled } from "./Button.style";

import { IButtonType } from "./Button.types";

const Button: FC<IButtonType> = ({
  value,
  symbol,
  onClick,
  type = undefined,
}) => {
  const { t } = useTranslation();

  return (
    <CreateButtonStyled
      // {...props}
      // className={className}
      // variant="contained"
      // disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {symbol && <PlusIcon />}
      <span className="ms-2">{value || t("common.create")}</span>
    </CreateButtonStyled>
  );
};

export default Button;
