export default function mergeOrderArrays(originalArray: any[], changesArray: any[]) {
  // Step 1: Create a map of _id to updated objects from the changesArray
  const idToUpdatedObject = {};
  for (const updatedObj of changesArray) {
    // @ts-ignore
    idToUpdatedObject[updatedObj._id] = updatedObj;
  }

  // Step 2: Merge the arrays while updating properties
  const mergedArray = originalArray.map((originalObj) => {
    if (idToUpdatedObject.hasOwnProperty(originalObj._id)) {
      // @ts-ignore
      return { ...originalObj, ...idToUpdatedObject[originalObj._id] };
    }
    return originalObj;
  });

  // Step 3: Append any remaining objects from the changesArray
  for (const updatedObj of changesArray) {
    if (!idToUpdatedObject.hasOwnProperty(updatedObj._id)) {
      mergedArray.push(updatedObj);
    }
  }

  return mergedArray;
}
