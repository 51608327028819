import { Controller, get } from "react-hook-form";

import Checkbox from "../CustomCheckbox/CustomCheckbox";

const ControlledCheckbox = ({
  control,
  name,
  errors,
  rules,
  ...props
}: any) => {
  const checkboxChangeHandler = (formChangeHandler: any) => (event: any) => {
    formChangeHandler(event?.target?.checked);
    const { onChange } = props;
    if (onChange) {
      onChange(event, event?.target?.checked);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Checkbox
          id={name}
          {...props}
          {...field}
          onChange={checkboxChangeHandler(field.onChange)}
          isRequired={
            typeof get(rules, "required", false) === "boolean"
              ? get(rules, "required", false)
              : get(rules, "required.value", false)
          }
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default ControlledCheckbox;
