import { Fragment, Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";

import { StyledApp } from "App.style";
import { Navbar, Sidebar } from "components";
import { CommonContext } from "context";
import { RUseCommon } from "context/useCommon";
import { REQUEST_STATUS } from "hooks";
import Loader from "pages/loader/container/Loader";
import { routes } from "routes/constants/Routes.constants";
import { hasToken } from "routes/utils/hasToken/hasToken";
import RoleManager from "services/roleManagement";
import browserStorage from "services/storage/browserStorage";

const Routes = () => {
  const [hasAccess] = RoleManager();

  return (
    <Switch>
      {/* <Route path="dashboard" element={<Dashboard />} /> */}
      {routes.map((route, index) => (
        <Fragment key={index}>
          {hasAccess(route.key) && <Route {...route} />}
        </Fragment>
      ))}
    </Switch>
  );
};

const ProjectRoutes = () => {
  const navigate = useNavigate();
  let browserStorageCollape: any = browserStorage.get("collapse");
  const [collapse, setCollapse] = useState<boolean>(
    browserStorageCollape ? JSON.parse(browserStorageCollape) : false
  );
  const [newChatLength, setNewChatLength] = useState(0);
  const admin: any = localStorage.getItem("admin");
  const agentId: any = JSON.parse(admin)?.agentId;

  const {
    state: {
      getChatsState: { getChatsData, getChatsStatus },
      chatRead,
      render,
      chatTitle,
    },
    actions: { setNotificationOpen, getChats },
  } = useContext<RUseCommon>(CommonContext);

  useEffect(() => {
    getChats(chatTitle);
  }, [render, chatRead]);

  useEffect(() => {
    if (getChatsStatus === REQUEST_STATUS.success) {
      setNewChatLength(
        getChatsData.data.filter((chat: any) => chat.isRead === false).length
      );
    }
  }, [getChatsStatus]);

  useEffect(() => {
    const isAgentNotFound = browserStorage.get("agentNotFound");
    if (!hasToken() && !isAgentNotFound) {
      // debugger;
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Suspense fallback={<Loader fullWidth size={60} />}>
        <div
          onClick={() => setNotificationOpen(false)}
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <Sidebar
            collapse={collapse}
            setCollapse={setCollapse}
            newChatLength={newChatLength}
          />
          <StyledApp collapse={collapse}>
            <Navbar collapse={collapse} />
            <Suspense fallback={<Loader />}>
              <div style={{ width: "100%", height: "calc(100% - 70px)" }}>
                <Routes />
              </div>
            </Suspense>
          </StyledApp>
        </div>
      </Suspense>
    </>
  );
};

export default ProjectRoutes;
