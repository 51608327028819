import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import "./style.css";
import { debounce } from "services/debounce/debounce";
// import { useTranslation } from "react-i18next";

interface InputProps {
  type?: string;
  id?: string;
  className?: string;
  label?: string;
  value?: any;
  onChange?: any;
  name?: string;
  placeholder?: string;
  setFilter?: any;
}

const CustomInput = ({
  type = "text",
  id,
  className,
  label,
  value,
  onChange,
  name,
  placeholder,
  setFilter,
}: InputProps) => {
  const [query] = useState(value);
  // const { t } = useTranslation();

  const changeHandler = (event: any) => {
    const { value, name } = event.target;
    setFilter((prev: any) => ({ ...prev, [name]: value }));
    // toast.success("success search");
    // setQuery(event.target.value);
    // onChange({
    //     target: {
    //         value: event.target.value,
    //         name: name
    //     }
    // })
  };
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), []);
  const { t } = useTranslation();
  return (
    <div className={`custom-input-group ${className}`}>
      {label && <div className={"input-label"}>{label}</div>}
      <input
        // placeholder={t([placeholder])}
        placeholder={placeholder || t("filter.search")}
        defaultValue={query}
        type={type}
        id={id}
        name={name}
        className={`custom-input-all`}
        onChange={debouncedChangeHandler}
        autoComplete="off"
      />
    </div>
  );
};

export default CustomInput;
