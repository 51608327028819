import get from "lodash.get";

import { useCommonContext } from "context/CommonContext";
import { AGENT_ROLES, IRoles, MAIN_ROLES } from "./role.types";
import browserStorage from "services/storage/browserStorage";

const RoleManager = (): [typeof hasAccess] => {
  const {
    state: {
      getRoleState: { getRoleData },
    },
  } = useCommonContext();

  const agentId = browserStorage.get("agentId");
  const isAgent = !!agentId;
  const isAdmin = !agentId;

  const hasAccess = (roleString: keyof IRoles | "notFound") => {
    // @ts-ignore
    if (isAdmin && !!MAIN_ROLES[roleString]) {
      try {
        return roleString === "notFound" || roleString === "_id"
          ? true
          : // @ts-ignore
            get(getRoleData, "data.role")[roleString];
      } catch (err) {
        return false;
      }
    } else if (
      isAgent &&
      // @ts-ignore
      !!AGENT_ROLES[roleString] &&
      roleString !== "_settings"
    ) {
      try {
        return roleString === "notFound" || roleString === "_id"
          ? true
          : // @ts-ignore
            get(getRoleData, "data.role")[roleString];
      } catch (err) {
        return false;
      }
    }
    return false;
  };

  return [hasAccess];
};

export default RoleManager;
