import styled from "styled-components";

export const MultiSelectStyled = styled.div`
  .MuiTextField-root {
    margin-top: 5px;
  }
  .MuiInputBase-root {
    min-height: 52px !important;
  }
  .css-eozd4h-MuiAutocomplete-root .MuiOutlinedInput-root {
    background: ${({ theme }) => theme.colors.bg.whiteBg};
    border-radius: 8px;
    padding: 2px 10px;
    /* border: 1px solid ${({ theme }) => theme.colors.border.grey}; */
  }
`;
