const EditIcon = (props: any) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.71 5.04006C18.1 4.65006 18.1 4.00006 17.71 3.63006L15.37 1.29006C15 0.900059 14.35 0.900059 13.96 1.29006L12.12 3.12006L15.87 6.87006L17.71 5.04006ZM0 15.2501V19.0001H3.75L14.81 7.93006L11.06 4.18006L0 15.2501Z"
        fill="#00B2F5"
      />
    </svg>
  );
};

export default EditIcon;
