const UserIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5715 10.2856C10.7017 10.2856 12.4286 8.55867 12.4286 6.42843C12.4286 4.29819 10.7017 2.57129 8.5715 2.57129C6.44126 2.57129 4.71436 4.29819 4.71436 6.42843C4.71436 8.55867 6.44126 10.2856 8.5715 10.2856Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2857 23.1424H0.857178V21.4282C0.857178 19.3822 1.66993 17.42 3.11664 15.9733C4.56335 14.5266 6.52551 13.7139 8.57146 13.7139C10.6174 13.7139 12.5796 14.5266 14.0263 15.9733C15.473 17.42 16.2857 19.3822 16.2857 21.4282V23.1424Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4287 2.57129C16.4517 2.57129 17.4328 2.97767 18.1561 3.70102C18.8795 4.42437 19.2859 5.40545 19.2859 6.42843C19.2859 7.45141 18.8795 8.43249 18.1561 9.15584C17.4328 9.8792 16.4517 10.2856 15.4287 10.2856"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1714 14.04C19.6317 14.5956 20.8889 15.5814 21.7766 16.8671C22.6643 18.1527 23.1407 19.6777 23.1428 21.24V23.1429H20.5714"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
