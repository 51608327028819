import { FC, useEffect } from "react";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { PhoneNumberCountryStyled } from "./PhoneInput.style";
//@ts-ignore
//@ts-ignore
import { IPhoneInputProps } from "./PhoneInput.types";
import "react-phone-input-2/lib/style.css";
import Label from "../Label/Label";
import Error from "../Error/Error";
import useFeatures from "services/useFeatures/useFeatures";
import { useCommonContext } from "context/CommonContext";

const PhoneInput: FC<IPhoneInputProps> = ({
  defaultValue = "",
  error,
  placeholder = "",
  name = "phoneNumber",
  label = "",
  className = "",
  rules = { required: false },
  control,
}) => {
  return (
    <div className={className}>
      <Controller
        name={name}
        rules={rules}
        control={control}
        render={({ field, fieldState, formState }) => {
          return (
            <>
              {!!label && <Label label={!!field?.ref ? label + "*" : ""} />}
              <PhoneInputContainer field={field} defaultValue={defaultValue} />
            </>
          );
        }}
      />
      {!!error && <Error message={error.message} />}
    </div>
  );
};

const PhoneInputContainer = ({
  field,
  defaultValue,
}: {
  field: ControllerRenderProps<any, string>;
  defaultValue?: string;
}) => {
  const {
    state: {
      getFeaturesState: {
        getFeaturesData: { data: features },
      },
    },
  } = useCommonContext();

  useEffect(() => {
    document.querySelector(".selected-flag")!?.setAttribute?.("tabindex", "2");
  }, []);

  const defaultCountry = () => {
    switch (features?.phonePrefix) {
      case "+998":
        return "uz";
      case "+7":
        return "kz";
      case "+996":
        return "kg";
      case "+992":
        return "tj";
      default:
        return "";
    }
  };

  return (
    <PhoneNumberCountryStyled
      country={defaultCountry()}
      preferredCountries={["kz", "uz", "tj", "kg", "tm"]}
      excludeCountries={["ru"]}
      placeholder={`${features?.phonePrefix || "+7-777"}--------`}
      {...field}
      onChange={(val) => {
        field.onChange("+" + val);
      }}
    />
  );
};

export default PhoneInput;
