import React from "react";

const MovementIcon = (props: any) => {
  return (
    <svg
      fill="#999"
      height="28px"
      width="28px"
      version="1.1"
      id="Capa_1"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 473.555 473.555"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M473.555,258.357l-83.906-54.618v31.301h-32.043c-17.201-50.205-64.866-86.396-120.828-86.396
       S133.151,184.835,115.95,235.04H83.906v-31.301L0,258.357l83.906,54.618v-31.301h77.104l-0.065-0.35h0.095v-5
       c0-41.761,33.976-75.736,75.736-75.736s75.736,33.976,75.736,75.736v2.539l-0.563,2.811h77.697v31.301L473.555,258.357z"
        />
        <path
          d="M236.777,230.282c-26.089,0-47.313,21.225-47.313,47.313s21.225,47.314,47.313,47.314s47.313-21.226,47.313-47.314
       S262.866,230.282,236.777,230.282z"
        />
      </g>
    </svg>
  );
};

export default MovementIcon;
