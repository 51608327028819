import { FC } from "react";
import { Badge as MuBadge } from "@mui/material";

import BellIcon from "../Navbar/assets/icon/BellIcon";
import { BadgeType } from "./Badge.types";

const Badge: FC<BadgeType> = ({ badgeContent = 6 }) => {
  return (
    <MuBadge badgeContent={badgeContent} color="error">
      <BellIcon />
    </MuBadge>
  );
};

export default Badge;
