import { useEffect, useState } from "react";

/**
 * The main purpose
 */
function useOrderUpdateDebounce<T>(
  updateOrder: T,
  newOrder: T,
  delay: number = 500,
  setFetchData: any // state to set fetch data
) {
  const [debouncedRows, setDebouncedRows] = useState<T[]>([]);
  const [prevRows, setPrevRows] = useState<T[]>([]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (updateOrder) {
      console.count("--update order count");

      setPrevRows([updateOrder, ...prevRows]);
      timer = setTimeout(() => {
        setDebouncedRows((prev) => {
          const newRows = [...prev, ...prevRows, updateOrder];
          return newRows;
        });
        setPrevRows([]);
      }, delay);
    } /*  else {
      setDebouncedRows((prev) => [...prev, updateOrder]);
    } */

    return () => {
      clearTimeout(timer);
    };
  }, [updateOrder]);

  useEffect(() => {
    if (newOrder) {
      console.log("--newOrder-added: ", newOrder);
      setFetchData((prev: any) => {
        const newOrders = [newOrder, ...prev];
        return newOrders;
      });
    }
  }, [newOrder]);

  return { debouncedRows, prevRows, setDebouncedRows };
}

export default useOrderUpdateDebounce;
