import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import get from "lodash.get";

import { REQUEST_STATUS, useRequest } from "hooks";
import { CommonContext } from "context";

import { IBasicTableProps } from "./BasicTable.types";

const BasicTable: React.FC<IBasicTableProps> = ({
  columns,
  dataUrl,
  filter = "",
}) => {
  const [client, res, status] = useRequest();
  // const [setParams] = SearchParams();

  const [fetchData, setFetchData] = useState<any[]>([]);
  const { t } = useTranslation();
  const {
    state: { render },
  } = useContext(CommonContext);
  const fetchDataFunction = () => {
    if (dataUrl) {
      // setParams(filter);
      // const params = new URLSearchParams(filter).toString();
      client.get(dataUrl);
    }
  };

  useEffect(() => {
    fetchDataFunction();
  }, [render, filter]);

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      setFetchData(get(res, "data.data") || get(res, "data"));
    }
  }, [status]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>№</TableCell>
            {columns.map((column: any, index) => (
              <TableCell key={index} align="right">
                {column?.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchData?.map((item: any, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="item">
                {index + 1}
              </TableCell>
              {columns.map((column, index) =>
                column?.renderItem ? (
                  <TableCell align="right" key={index}>
                    {column.renderItem(item, column, index)}
                  </TableCell>
                ) : (
                  <TableCell align="right" key={index}>
                    {get(item, `${column.dataKey as string}`)}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!fetchData.length && (
        <p className="text-center my-3">
          {t("components.table.data_not_found")}
        </p>
      )}
    </TableContainer>
  );
};

export default BasicTable;
