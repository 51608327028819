import { ConfigProvider, DatePicker } from "antd";
import { TSetState } from "constants/types";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import IMask from "imask";

import { RangeTimePickerStyled } from "../RangeTimePicker/RangeTimePicker.styled";

const DATE_FORMAT = "DD-MM-YYYY";

const MASKED = IMask.createMask({
  blocks: {
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
  },
  format: (date: Date) => dayjs(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => dayjs(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});
dayjs.extend(customParseFormat);
const disabledDate = (current: Dayjs | null): boolean | null => {
  // Disable all dates before today
  return current && current.isBefore(dayjs().startOf("day"), "day");
};

export type TRangeTimeValue = [Dayjs, Dayjs] | [null, null];
interface IRangeTimeProps {
  value: TRangeTimeValue;
  onChange: TSetState<TRangeTimeValue>;
  setFilter?: TSetState<TRangeTimeValue>;
  disablePrevDate?: boolean;
}

const RangeDatePicker: React.FC<IRangeTimeProps> = ({
  onChange,
  value,
  setFilter,
  disablePrevDate,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#4ce5b1",
        },
      }}
    >
      <RangeTimePickerStyled>
        <DatePicker.RangePicker
          aria-label="ok"
          value={value}
          // @ts-ignore // dayjs!
          placeholder={[
            dayjs().subtract(3, "months").format(DATE_FORMAT),
            dayjs().format(DATE_FORMAT),
          ]}
          format={DATE_FORMAT}
          onChange={(values) => {
            // @ts-ignore // dayjs!
            onChange(values);
            setFilter?.((prev: any) => ({
              ...prev,
              from: values?.[0]?.startOf("day").format(),
              to: values?.[1]?.endOf("day").format(),
            }));
          }}
          picker="date"
          onKeyDown={(event) => {
            const input = event.target as HTMLInputElement;
            input.value = MASKED.resolve(input.value);
          }}
          // @ts-ignore
          disabledDate={disablePrevDate && disabledDate}

          // defaultValue={[
          //   dayjs("12:00", "HH:mm"),
          //   dayjs("12:00", "HH:mm").add(1, "hour"),
          // ]}
          // status={!!fieldState.error ? "error" : undefined}
          // {...field}
        />
        <>
          {/* {!!fieldState.error && (
            <Error
              message={fieldState?.error?.message || "error in time picker"}
            />
          )} */}
        </>
      </RangeTimePickerStyled>
    </ConfigProvider>
  );
};

export default RangeDatePicker;
