import React, { useState } from "react";
import { Control, Controller, FieldPath } from "react-hook-form";
import { TRules } from "constants/types";
import { TextEditorStyled } from "./TextEditor.style";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

export interface ITextEditor<FormNames extends Record<string, any>> {
  control: Control<FormNames>;
  name: FieldPath<FormNames>;
  rules?: TRules<FormNames>;
  label?: string;
  placeholder?: string | number;
  type?: React.InputHTMLAttributes<unknown>["type"];
  multiline?: boolean;
  disabled?: boolean;
  onCustomChange?: (val: string) => void;
  inputProps?: any;
}
const TextEditor = <FormNames extends Record<string, any>>({
  control,
  name,
}: ITextEditor<FormNames>) => {
  const initialValue =
    "<p>Your initial <b>html value</b> or an empty string to init editor without value</p>";

  const [value, setValue] = useState(initialValue);
  return (
    <Controller
      name={name}
      control={control}
      // @ts-ignore
      defaultValue=""
      render={({ field, fieldState, formState }) => (
        <TextEditorStyled>
          <ReactQuill {...field} />
        </TextEditorStyled>
      )}
    />
  );
};

export default TextEditor;
