import styled from "styled-components";

export const TimePickerFormStyled = styled.div`
  .ant-picker {
    width: 100%;
    padding: 13px;
    border: 1px solid ${({ theme }) => theme.colors.border.grey};
    border-radius: 8px;
    input {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
  }
`;
