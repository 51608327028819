import { SidebarList } from "components/shared/Sidebar/SideItems";
import { CommonContext } from "context";
import { REQUEST_STATUS, useRequest } from "hooks";
import get from "lodash.get";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import browserStorage from "services/storage/browserStorage";
import { ILoginForm } from "../components/LoginForm/LoginForm.types";
import { ILoginResponse, IUseLogin } from "./LoginContext.types";
import { RUseCommon } from "context/useCommon";

export const useLogin = () => {
  const {
    state: {
      getRoleState: { getRoleData, getRoleStatus },
    },
    actions: { updateWindow },
  } = useContext<RUseCommon>(CommonContext);
  const [loginClient, loginData, loginStatus, loginError] =
    useRequest<ILoginResponse>();

  const loginFunction = async (loginQuery: ILoginForm) => {
    await loginClient.post("/login", loginQuery);
  };

  useEffect(() => {
    if (loginStatus === REQUEST_STATUS.success) {
      updateWindow({ loginData });
    }
  }, [loginStatus]);

  return {
    state: {
      loginState: { loginData, loginStatus, loginError },
    },
    actions: { loginFunction },
  };
};
