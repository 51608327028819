import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import host from "constants/environment";

import browserStorage from "services/storage/browserStorage";

const token = browserStorage.get("token");

const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 260000, // 15.000
  headers: {
    Authorization: `Bearer ${token}`,
    // "Agent-Prefix": process.env.REACT_APP_AGENT! || "",
  },
};

axios.interceptors.request.use(
  (config) => {
    const token = browserStorage.get("token");
    return {
      ...config,
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 260000, // 31.000
      headers: {
        // "Agent-Prefix": process.env.REACT_APP_AGENT || "",
        Authorization: `Bearer ${token}`,
        test: host,
        lang: browserStorage.get("i18nextLng"),
      },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axiosInstance: AxiosInstance = axios.create(config);

export default axios;
