const CardIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1898 8.45203V6.63227H7.45148V8.45203H20.1898ZM20.1898 15.7311V11.1817H7.45148V15.7311H20.1898ZM20.1898 4.8125C20.6725 4.8125 21.1353 5.00422 21.4766 5.3455C21.8179 5.68677 22.0096 6.14963 22.0096 6.63227V15.7311C22.0096 16.2137 21.8179 16.6766 21.4766 17.0179C21.1353 17.3591 20.6725 17.5509 20.1898 17.5509H7.45148C6.44151 17.5509 5.63172 16.732 5.63172 15.7311V6.63227C5.63172 5.6223 6.44151 4.8125 7.45148 4.8125H20.1898ZM3.81195 19.3706H17.4602V21.1904H3.81195C3.32932 21.1904 2.86646 20.9987 2.52518 20.6574C2.18391 20.3161 1.99219 19.8533 1.99219 19.3706V9.36191H3.81195V19.3706Z"
        fill="#313649"
      />
    </svg>
  );
};

export default CardIcon;
