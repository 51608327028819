import { FC, Fragment } from "react";
import { Checkbox as MuiCheckbox, FormControlLabel } from "@mui/material";

const Checkbox: FC<any> = ({ label = "", isRequired, position, ...props }) => {
  return (
    <FormControlLabel
      value={position}
      label={
        <Fragment>
          {label} {isRequired && <span>*</span>}
        </Fragment>
      }
      {...props}
      control={<MuiCheckbox />}
    />
  );
};

export default Checkbox;
