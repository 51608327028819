import { FC } from "react";
import { Switch as MuiSwitch, FormControlLabel } from "@mui/material";

const Switch: FC<any> = ({ label = "", value = false, ...props }) => {
  return (
    <FormControlLabel
      label={label}
      checked={value}
      {...props}
      control={<MuiSwitch />}
    />
  );
};

export default Switch;
