import styled from "styled-components";

export const RangeTimePickerStyled = styled.div`
  .ant-picker-range {
    height: 52px;
    width: 100%;
    border-radius: 12px;
    input {
      font-size: 16px;
    }
  }
`;
