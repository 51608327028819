const getSubdomain = (urlString: string) => {
  return urlString?.slice(urlString?.indexOf("/") + 2, urlString?.indexOf("."));
};
const url = window.location.href;
const subDomain = getSubdomain(url);

const isAdmin =
  subDomain.startsWith("admin") ||
  subDomain.startsWith("localhost") ||
  subDomain.startsWith("fasting"); //! here is isAdmin

export default isAdmin;
