import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { SearchParams } from "services/urlParams/urlParams";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

type TDateVal = any;

const CustomDatePicker = ({
  fromDate = "dateFrom",
  toDate = "dateTo",
  onChange,
  setFilter,
}: {
  fromDate?: string;
  toDate?: string;
  onChange?: (val: TDateVal) => void;
  value?: TDateVal;
  setFilter: React.Dispatch<React.SetStateAction<any>>;
}) => {
  const [, getParams] = SearchParams();
  const [fromTo, setFromTo] = useState<any>([null, null]);

  useEffect(() => {
    if (getParams()[fromDate] && getParams()[toDate]) {
      setFromTo([dayjs(getParams()[fromDate]), dayjs(getParams()[toDate])]);
    } else {
      setFromTo([null, null]);
    }
  }, []);

  return (
    <>
      <RangePicker
        showTime
        defaultValue={fromTo}
        onChange={(e: any) => {
          const from = e?.[0];
          const to = e?.[1];

          if (!!from && !!to) {
            setFilter({
              ...getParams(),
              [fromDate]: dayjs(from).toISOString(),
              [toDate]: dayjs(to).toISOString(),
            });
          } else if (!from && !to) {
            setFilter({ ...getParams(), [fromDate]: null, [toDate]: null });
          }
        }}
      />
    </>
  );
};
export default CustomDatePicker;
