export interface IRoles {
  _id: string; //! it makes role public
  _settings: string;
  // name: string;
  // agentId?: null | string;
  // isDeleted: boolean;

  notFound: boolean;

  agent: boolean;
  agentCreate: boolean;
  agentUpdate: boolean;
  agentDelete: boolean;

  dashboard: boolean;

  balance: boolean;
  balanceFill: boolean;
  balanceTake: boolean;
  balanceTransfer: boolean;

  order: boolean;
  orderCreate: boolean;
  orderUpdate: boolean;
  orderCancel: boolean;
  orderSetDriver: boolean;

  orderHistory: boolean;

  employee: boolean;
  employeeCreate: boolean;
  employeeUpdate: boolean;
  employeeDelete: boolean;

  role: boolean;
  roleCreate: boolean;
  roleUpdate: boolean;
  roleDelete: boolean;

  driver: boolean;
  driverCreate: boolean;
  driverUpdate: boolean;
  driverDelete: boolean;

  user: boolean;
  userDiscount: boolean;

  brand: boolean;
  brandCreate: boolean;
  brandUpdate: boolean;
  brandDelete: boolean;

  model: boolean;
  modelCreate: boolean;
  modelUpdate: boolean;
  modelDelete: boolean;

  city: boolean;
  cityCreate: boolean;
  cityUpdate: boolean;
  cityDelete: boolean;

  tariff: boolean;
  tariffCreate: boolean;
  tariffUpdate: boolean;
  tariffDelete: boolean;

  place: boolean;
  placeCreate: boolean;
  placeUpdate: boolean;
  placeDelete: boolean;

  station: boolean;
  stationCreate: boolean;
  stationUpdate: boolean;
  stationDelete: boolean;

  additionalFeature: boolean;
  additionalFeatureCreate: boolean;
  additionalFeatureUpdate: boolean;
  additionalFeatureDelete: boolean;

  promocode: boolean;
  promocodeCreate: boolean;
  promocodeUpdate: boolean;
  promocodeDelete: boolean;

  reason: boolean;
  reasonCreate: boolean;
  reasonUpdate: boolean;
  reasonDelete: boolean;

  card: boolean;
  cardCreate: boolean;
  cardUpdate: boolean;
  cardDelete: boolean;

  settingMap: boolean;
  settingMapUpdate: boolean;

  additionalSettings: boolean;

  orderRetryDriverSearch: boolean;

  rateSettings: boolean;
  rateSettingsUpdate: boolean;

  photoControl: boolean;
  photoControlUpdate: boolean;

  activity: boolean;
  activityUpdate: boolean;

  bonusSettings: boolean;
  bonusSettingsUpdate: boolean;

  userExport: boolean;

  integration: boolean;
}

export const MAIN_ROLES = {
  _id: "_id",
  notFound: "notFound",
  agent: "agent",
  agentCreate: "agentCreate",
  agentUpdate: "agentUpdate",
  agentDelete: "agentDelete",

  dashboard: "dashboard",

  balance: "balance",
  balanceFill: "balanceFill",
  balanceTake: "balanceTake",

  order: "order",
  orderCreate: "orderCreate",
  orderUpdate: "orderUpdate",
  orderCancel: "orderCancel",
  orderSetDriver: "orderSetDriver",

  orderHistory: "orderHistory",

  employee: "employee",
  employeeCreate: "employeeCreate",
  employeeUpdate: "employeeUpdate",
  employeeDelete: "employeeDelete",

  role: "role",
  roleCreate: "roleCreate",
  roleUpdate: "roleUpdate",
  roleDelete: "roleDelete",

  driver: "driver",
  driverCreate: "driverCreate",
  driverUpdate: "driverUpdate",
  driverDelete: "driverDelete",

  user: "user",
  userDiscount: "userDiscount",

  brand: "brand",
  brandCreate: "brandCreate",
  brandUpdate: "brandUpdate",
  brandDelete: "brandDelete",

  model: "model",
  modelCreate: "modelCreate",
  modelUpdate: "modelUpdate",
  modelDelete: "modelDelete",

  city: "city",
  cityCreate: "cityCreate",
  cityUpdate: "cityUpdate",
  cityDelete: "cityDelete",

  tariff: "tariff",
  tariffCreate: "tariffCreate",
  tariffUpdate: "tariffUpdate",
  tariffDelete: "tariffDelete",

  place: "place",
  placeCreate: "placeCreate",
  placeUpdate: "placeUpdate",
  placeDelete: "placeDelete",

  settingMap: "settingMap",
  settingMapUpdate: "settingMapUpdate",

  activity: "activity",
  activityUpdate: "activityUpdate",

  bonusSettings: "bonusSettings",
  bonusSettingsUpdate: "bonusSettingsUpdate",

  station: "station",
  stationCreate: "stationCreate",
  stationUpdate: "stationUpdate",
  stationDelete: "stationDelete",

  photoControl: "photoControl",
  photoControlUpdate: "photoControlUpdate",

  additionalFeature: "additionalFeature",
  additionalFeatureCreate: "additionalFeatureCreate",
  additionalFeatureUpdate: "additionalFeatureUpdate",
  additionalFeatureDelete: "additionalFeatureDelete",

  promocode: "promocode",
  promocodeCreate: "promocodeCreate",
  promocodeUpdate: "promocodeUpdate",
  promocodeDelete: "promocodeDelete",

  rateSettings: "rateSettings",
  rateSettingsUpdate: "rateSettingsUpdate",

  reason: "reason",
  reasonCreate: "reasonCreate",
  reasonUpdate: "reasonUpdate",
  reasonDelete: "reasonDelete",

  additionalSettings: "additionalSettings",

  orderRetryDriverSearch: "orderRetryDriverSearch",
  integration: "integration",
};

export const AGENT_ROLES = {
  dashboard: "dashboard",

  balance: "balance",
  balanceFill: "balanceFill",
  balanceTake: "balanceTake",
  balanceTransfer: "balanceTransfer",

  card: "card",
  cardCreate: "cardCreate",
  cardUpdate: "cardUpdate",
  cardDelete: "cardDelete",

  order: "order",
  orderCreate: "orderCreate",
  orderUpdate: "orderUpdate",
  orderCancel: "orderCancel",
  orderSetDriver: "orderSetDriver",

  orderHistory: "orderHistory",

  employee: "employee",
  employeeCreate: "employeeCreate",
  employeeUpdate: "employeeUpdate",
  employeeDelete: "employeeDelete",

  role: "role",
  roleCreate: "roleCreate",
  roleUpdate: "roleUpdate",
  roleDelete: "roleDelete",

  driver: "driver",
  driverCreate: "driverCreate",
  driverUpdate: "driverUpdate",
  driverDelete: "driverDelete",

  user: "user",

  tariff: "tariff",

  place: "place",
  placeCreate: "placeCreate",
  placeUpdate: "placeUpdate",
  placeDelete: "placeDelete",

  orderRetryDriverSearch: "orderRetryDriverSearch",
  integration: "integration",
};
