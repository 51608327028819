import { FC } from "react";
import { CheckboxStyled } from "./Checkbox.style";
import { CheckboxPropsType } from "./Checkbox.types";
import { Checkbox as MUICheckbox } from "@mui/material";
import { Controller } from "react-hook-form";

const Checkbox: FC<CheckboxPropsType> = ({
  label = "",
  control,
  name,
  defaultChecked = false,
  change,
}) => {
  return (
    <CheckboxStyled
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <MUICheckbox
                defaultChecked={defaultChecked}
                checked={field.value}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  change?.(e);
                }}
              />
            );
          }}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
