import styled from "styled-components";

export const StyledNavbar = styled.div<any>`
  height: 70px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  transition: all 0.18s ease-in-out;
  /* position: fixed;
  top: 0;
  right: 0 !important; */
  /* padding: 15px 30px 0 !important; */
  /* transition: all 0.3s linear; */
  /* right: ${(props) => (props.collapse ? "340px" : "90px")}; */
  /* width: ${(props) =>
    props.collapse
      ? "calc(100vw - max(20vw, 300px))"
      : "calc(100vw - max(85px, 85px))"};
 */
  /* z-index: 99; */

  & .MuiAppBar-root {
    position: relative;
    background-color: ${({ theme }) => theme.colors.bg.whiteBg};
    box-shadow: none;
    width: 100%;

    .MuiContainer-root.MuiContainer-maxWidthXl {
      margin: 0 !important;
      min-width: 100% !important ;
    }
  }
  .navbarContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .breadcrumb {
      color: ${({ theme }) => theme.colors.color.black};
    }
    .navbarProfile {
      margin-left: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
export const RoundedButton = styled.button`
  border-radius: 50%;
  padding: 8px 10px;
  background: ${({ theme }) => theme.colors.button.lightBlue};
  transition: all 0.2s;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
  svg {
    path {
      fill: ${({ theme }) => theme.colors.color.black};
    }
  }
  &:hover {
    background: rgb(219, 229, 255);
  }
`;
export const UserStyled = styled.button`
  background: ${({ theme }) => theme.colors.button.lightBlue};
  border-radius: 35px;
  padding: 5px 3.5px;
  transition: all 0.2s;
  box-shadow: none !important;
  cursor: pointer;
  border: none;
  span {
    padding: 8.4px 5.5px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.color.black};
  }
  img {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    overflow: hidden;
  }
  &:hover {
    background: rgb(219, 229, 255);
  }
`;
