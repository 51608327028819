const HrIcon = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0001 7.71456C13.8937 7.71456 15.4287 6.17954 15.4287 4.28599C15.4287 2.39245 13.8937 0.857422 12.0001 0.857422C10.1066 0.857422 8.57153 2.39245 8.57153 4.28599C8.57153 6.17954 10.1066 7.71456 12.0001 7.71456Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 13.7148C18 12.1235 17.3679 10.5974 16.2426 9.4722C15.1174 8.34699 13.5913 7.71484 12 7.71484C10.4087 7.71484 8.88258 8.34699 7.75736 9.4722C6.63214 10.5974 6 12.1235 6 13.7148V16.2863H8.57143L9.42857 23.1434H14.5714L15.4286 16.2863H18V13.7148Z"
        stroke="#000001"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HrIcon;
