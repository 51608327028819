import styled from "styled-components";

export const StyledTimePicker = styled("div")(({ theme }) => ({
  "& .MuiOutlinedInput-root": {},
  "& .MuiFormControl-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    // borderColor: "rgba(0, 0, 0, 0.23) !important",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    // borderColor: "#000 !important",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    //   borderColor: `${theme.palette.primary.main} !important`,
  },
  "& .MuiOutlinedInput-input": {
    padding: "14px 14px",
  },
  "&.error ": {
    // border: "red 1px solid",
  },
}));
