import { AppBar, Container, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import browserStorage from "services/storage/browserStorage";

import BreadCrumbs from "./components/BreadCrumbs/BreadCrumbs";
import ChangeLanguage from "./components/ChangeLanguage/ChangeLanguage";
import Notifications from "./components/Notifications/Notifications";
import Profile from "./components/Profile/container";

import LogOutIcon from "./assets/icon/LogOut";

import { RoundedButton, StyledNavbar } from "./Navbar.style";
import { useSocket } from "services/socket/useSocket";

const ResponsiveAppBar = ({ collapse }: any) => {
  const navigate = useNavigate();
  useSocket();

  const logout = async () => {
    const localLang: any = await browserStorage.get("i18nextLng");
    await localStorage.clear();
    await browserStorage.set("i18nextLng", localLang);
    navigate("/login");
  };

  return (
    <StyledNavbar collapse={collapse}>
      <AppBar>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <div className="navbarContent">
              <div className="breadcrumb">
                <BreadCrumbs />
              </div>
              <div className="navbarProfile">
                <Notifications />
                <Profile />
                <ChangeLanguage />
                <RoundedButton className={"btn ml-auto"} onClick={logout}>
                  <LogOutIcon />
                </RoundedButton>
              </div>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </StyledNavbar>
  );
};
export default ResponsiveAppBar;
