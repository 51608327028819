import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItemIcon, ListItemText, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Analytics, Password } from "@mui/icons-material";
import get from "lodash.get";

import browserStorage from "services/storage/browserStorage";
import { IAdmin } from "context/useCommon";

import ChangePassword from "../components/ChangePassword/ChangePassword";
//@ts-ignore
import UserImageDefault from "../../../assets/icon/user.svg";
import { UserStyled } from "../../../Navbar.style";
import MovementIcon from "assets/icons/MovementIcon";

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userInfoGetStorage: any = browserStorage.get("admin");
  const userInfo: IAdmin = useMemo(() => {
    if (!!userInfoGetStorage) return JSON.parse(userInfoGetStorage);
    else return {};
  }, [userInfoGetStorage]);
  return (
    <>
      <UserStyled
        id="basic-button"
        className={
          "btn ml-auto mx-3 d-flex align-items-center justify-content-center"
        }
        onClick={handleClick}
      >
        <img src={UserImageDefault} alt="User" />
        <span>
          {get(userInfo, "firstName", "") + " " + get(userInfo, "lastName", "")}
        </span>
      </UserStyled>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {userInfo?.agentId ? (
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <Analytics fontSize="small" />
            </ListItemIcon>
            <ListItemText
              onClick={() =>
                navigate("agents/details/" + userInfo.agentId, {
                  replace: true,
                })
              }
            >
              {t("navbar.profile.profile")}
            </ListItemText>
          </MenuItem>
        ) : null}
        <MenuItem onClick={() => setOpenModal(true)}>
          <ListItemIcon>
            <Password fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {t("navbar.profile.change_password.list_item_text")}
          </ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/movement-history");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <MovementIcon />
          </ListItemIcon>
          <ListItemText>
            {t("navbar.profile.change_password.order_history_text")}
          </ListItemText>
        </MenuItem>
      </Menu>
      <ChangePassword open={openModal} setOpen={setOpenModal} />
    </>
  );
};

export default Profile;
