import { useEffect, useRef, useState } from "react";
// @ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import { SearchParams } from "services/urlParams/urlParams";
import useOutsideHandler from "hooks/outsideHandler/outsideHandler";
import CalendarIcon from "assets/icons/CalendarIcon";

import {
  // CustomHeaderStyled,
  FromToDatePickerStyled,
} from "./FromToDatePicker.style";
import { useTranslation } from "react-i18next";
// import { DATE_PICKER_ADDITIONS_DATES } from "./FromToDatePicker.constants";

const FromToDatePicker = (props: {
  setFilter?: (props: any) => any;
  fromDateField?: string;
  toDateField?: string;
  isClearable?: boolean;
  defaultDates?: any;
  maxDate?: Date | null | undefined;
}) => {
  const {
    setFilter,
    fromDateField = "dateFrom",
    toDateField = "dateTo",
    isClearable = true,
    defaultDates,
  } = props;
  const [, getParams] = SearchParams();
  const [startDate, setStatrDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [, setIsMenuOpen] = useState<boolean>(false);
  const outsideOfSelectRef = useRef<any>();
  useOutsideHandler(outsideOfSelectRef, setIsOpen);
  const inputRef = useRef<any>();

  const { t } = useTranslation();

  useEffect(() => {
    if (getParams()[fromDateField] && getParams()[toDateField]) {
      let dateFrom = new Date(getParams()[fromDateField]);
      let dateTo = new Date(getParams()[toDateField]);
      setEndDate(dateTo);
      setStatrDate(dateFrom);
    } else if (defaultDates) {
      const [start, end] = defaultDates;
      setStatrDate(start);
      setEndDate(end);
      const dateStart = new Date(start);
      const dateEnd = new Date(end);
      setFilter?.((prev: any) => {
        return {
          ...prev,
          [fromDateField]: dateStart.toISOString(),
          [toDateField]: dateEnd.toISOString(),
        };
      });
    }
  }, [defaultDates]);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStatrDate(start);
    setEndDate(end);

    if (start && end) {
      let sec = end.valueOf() + (1000 * 60 * 60 * 24 - 1);
      let time = new Date(sec);

      const dateStart = new Date(start.toString());
      const dateEnd = new Date(time.toISOString());

      // vaqtni local vaqtga ozgartirish
      // dateStart.toLocaleString("uz-UZ", { timeZone: "Asia/Tashkent" });
      // dateEnd.toLocaleString("uz-UZ", { timeZone: "Asia/Tashkent" });

      setFilter?.((prev: any) => {
        return {
          ...prev,
          [fromDateField]: dateStart.toISOString(),
          [toDateField]: dateEnd.toISOString(),
        };
      });
      setIsOpen(false);
    }
    if (!start && !end) {
      setFilter?.((prev: any) => {
        return {
          ...prev,
          [fromDateField]: null,
          [toDateField]: null,
        };
      });
    }
  };

  const createAdditionalMenu = () => {
    const popper = document.querySelector(".react-datepicker-popper");

    const additoonalMenu = document.createElement("div");
    additoonalMenu.innerText = "";
    popper?.appendChild(additoonalMenu);
  };

  // const additionalMenuItemClick = (newDates: Date[]) => {
  //   onChange(newDates);
  // };

  // const renderCustomHeader = () => (
  //   <CustomHeaderStyled>
  //     <ul className="list-unstyled m-0 p-0">
  //       {DATE_PICKER_ADDITIONS_DATES.map((date) => (
  //         <li
  //           onClick={() => {
  //             additionalMenuItemClick([date.startDate, date.endDate]);
  //             setIsMenuOpen(false);
  //           }}
  //           className="filtered-date-item"
  //         >
  //           {date.title}
  //         </li>
  //       ))}
  //     </ul>
  //   </CustomHeaderStyled>
  // );

  document.addEventListener("mousedown", (e: any) => {
    if (e.target.className !== "filtered-date-item") {
      setIsMenuOpen(false);
    }
  });

  return (
    <FromToDatePickerStyled>
      <div
        className="date-picer-custom d-flex justify-content-center align-items-center
        range-datepiker"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="calenderIcon"
          onClick={() => {
            setIsOpen(true);
            setIsMenuOpen(true);
            inputRef.current.input.focus();
          }}
        >
          <CalendarIcon />
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <div className="date-picer-start" ref={outsideOfSelectRef}>
            <DatePicker
              dateFormat="dd/MM/yy"
              startDate={startDate}
              endDate={endDate}
              selected={startDate}
              placeholderText={t("common.date_picker_placeholder")}
              isClearable={isClearable}
              selectsRange={true}
              onChange={onChange}
              maxDate={props?.maxDate}
              open={isOpen}
              ref={inputRef}
              showPopperArrow={false}
              onCalendarClose={() => setIsOpen(false)}
              // renderCustomHeader={renderCustomHeader}
              onCalendarOpen={() => {
                setIsOpen(true);
                setIsMenuOpen(true);
                createAdditionalMenu();
              }}
              onKeyDown={(e: any) => {
                e.preventDefault();
              }}
            />
            {/* {isMenuOpen && renderCustomHeader()} */}
          </div>
        </div>
      </div>
    </FromToDatePickerStyled>
  );
};

export default FromToDatePicker;
