import { DialogContent, DialogTitle, Grid, Typography } from "@mui/material";

import { IModalT } from "./types/Modal.types";

import Cancel from "./assets/icons/Cancel";

import { ModalStyle } from "./styles/Modal.style";

const Modal = ({ children, open, closeModal, title, onClose }: IModalT) => {
  return (
    <ModalStyle
      open={open}
      onClose={(params, reason) => {
        onClose?.();
        closeModal?.();
      }}
    >
      <div className="cancel-button" onClick={closeModal}>
        <Cancel />
      </div>
      <DialogTitle>
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="center" alignItems="center">
          {children}
        </Grid>
      </DialogContent>
    </ModalStyle>
  );
};

export default Modal;
