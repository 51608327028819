import { useContext, useEffect } from "react";
import { Input, PhoneInput, Title } from "components";
import { LoginContext } from "pages/login/context";
import { useForm } from "react-hook-form";
import { LoginButton } from "../index";
import { formNames } from "./LoginForm.constants";
import { ILoginForm } from "./LoginForm.types";
import { CommonContext } from "context";
import { RUseCommon } from "context/useCommon";
import { IUseLogin } from "pages/login/context/LoginContext.types";
import { useNavigate } from "react-router-dom";
import browserStorage from "services/storage/browserStorage";

const LoginForm = () => {
  const navigate = useNavigate();
  const {
    state: {
      loginState: { loginStatus },
    },
    actions: { loginFunction },
  } = useContext<IUseLogin>(LoginContext);

  const {
    state: {
      getDefaultPhoneState: {
        getDefaultPhoneData,
        getDefaultPhoneStatus,
        getDefaultPhoneError,
      },
    },
  } = useContext<RUseCommon>(CommonContext);

  const { register, handleSubmit, formState, control, setValue } =
    useForm<ILoginForm>();

  const loginFormFunction = (formData: any) => {
    let loginQuery = {
      ...formData,
      [formNames.phoneNumber]: formData[formNames.phoneNumber]?.replace(
        /\s+/g,
        ""
      ),
    };
    loginFunction(loginQuery);
  };

  useEffect(() => {
    if (getDefaultPhoneStatus === "SUCCESS") {
      setValue(formNames.phoneNumber, getDefaultPhoneData.data?.phonePrefix);
    }
    if (getDefaultPhoneStatus === "FAILED") {
      if (getDefaultPhoneError?.response?.data?.code === 13000) {
        browserStorage.set("agentNotFound", true);
        navigate("/notFound");
      } else {
        browserStorage.remove("agentNotFound")
      }
    }
  }, [getDefaultPhoneStatus]);

  return (
    <form onSubmit={handleSubmit(loginFormFunction)}>
      <Title title="Admin panel" className="mb-3" />
      <PhoneInput
        control={control}
        params={{
          ...register(formNames.phoneNumber, {
            required: {
              value: true,
              message: "Telefon raqamingizni kiriting!",
            },
            minLength: {
              value: 9,
              message: "Telefon raqamingizni to'liq holatda kiriting!",
            },
            // pattern: {
            //   value: phoneNumberPattern,
            //   message: "Telefon raqamingirni kiriting",
            // },
          }),
        }}
        name={formNames.phoneNumber}
        error={formState.errors[formNames.phoneNumber]}
        label="Login"
        className="mb-4"
      />

      <Input
        params={{
          ...register(formNames.password, {
            required: { value: true, message: "Password kiriting" },
            minLength: { value: 6, message: "Password 6 simvoldan kam emas" },
          }),
          type: "password",
        }}
        error={formState.errors[formNames.password]}
        label="Password"
        className="mb-5"
      />
      <LoginButton type="submit" value="Login" loading={loginStatus} />
    </form>
  );
};

export default LoginForm;
