const ChatIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9781 0.0470734C6.87656 0.445511 3.3375 2.8502 1.5 6.49238C0.632813 8.20332 0.234375 9.88613 0.234375 11.8221C0.234375 14.2971 1.02188 16.7158 2.475 18.6986C2.63438 18.9189 2.76562 19.1064 2.76562 19.1252C2.76562 19.1393 2.37656 20.058 1.89844 21.1689C1.24688 22.6877 1.03125 23.2268 1.03125 23.3721C1.03125 23.6346 1.19063 23.8643 1.43438 23.9486C1.61719 24.0143 1.77656 23.9908 4.84219 23.4518L8.0625 22.8846L8.49375 23.0252C9.64688 23.3908 10.6781 23.5408 12 23.5455C13.9266 23.5502 15.5625 23.1658 17.2641 22.308C20.8781 20.4893 23.325 16.9314 23.7188 12.9236C23.7984 12.1033 23.7563 10.608 23.6297 9.8627C23.3016 7.92207 22.575 6.23457 21.3844 4.64082C20.925 4.02676 19.9031 2.98145 19.3125 2.52676C17.625 1.21895 15.8109 0.440823 13.6875 0.117386C13.0969 0.0283222 11.5875 -0.00917816 10.9781 0.0470734ZM13.2656 1.36426C14.2875 1.48145 15.5484 1.8377 16.4719 2.26895C18.5578 3.24395 20.3484 4.98301 21.375 7.03145C22.4484 9.17363 22.7578 11.4986 22.2844 13.8658C21.5063 17.7799 18.4688 20.9627 14.5594 21.9611C12.6891 22.4393 10.5094 22.3596 8.74219 21.7502C8.5125 21.6658 8.23594 21.6049 8.12813 21.6049C8.025 21.6049 6.79219 21.8064 5.38594 22.0549C3.98438 22.2986 2.80781 22.5002 2.77969 22.5002C2.75156 22.5002 3.05625 21.7361 3.45469 20.8033C4.30313 18.8252 4.28438 18.9377 3.86719 18.408C2.27813 16.383 1.5375 14.2736 1.53281 11.7658C1.52813 10.4861 1.67813 9.59082 2.08594 8.39082C2.62031 6.83926 3.39375 5.60645 4.59375 4.40176C5.49375 3.49707 6.20625 2.97676 7.30313 2.42364C9.14062 1.49082 11.1984 1.1252 13.2656 1.36426Z"
        fill="black"
      />
      <path
        d="M7.83754 9.45512C7.67348 9.48793 7.42036 9.76449 7.38754 9.95668C7.33598 10.2332 7.49067 10.5286 7.75786 10.6457C7.95942 10.7348 8.05317 10.7395 9.83442 10.7254L11.7047 10.7114L11.8688 10.5848C12.1594 10.3645 12.2157 9.96137 11.9907 9.68012C11.7938 9.42699 11.7375 9.42231 9.76411 9.42699C8.76567 9.43168 7.89848 9.44574 7.83754 9.45512Z"
        fill="black"
      />
      <path
        d="M7.71087 12.7406C7.52806 12.8531 7.45775 12.9422 7.40618 13.1297C7.34525 13.3406 7.42025 13.5891 7.59368 13.7578L7.739 13.8984H11.9999H16.2609L16.4015 13.7625C16.6968 13.4812 16.6968 13.0969 16.4062 12.8203L16.2609 12.6797L12.0562 12.6656C8.039 12.6562 7.84681 12.6609 7.71087 12.7406Z"
        fill="black"
      />
    </svg>
  );
};

export default ChatIcon;
