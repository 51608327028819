import styled from "styled-components";
import { Dialog } from "@mui/material";

export const ModalStyle = styled(Dialog)({
  "& .MuiDialog-paper ": {
    borderRadius: "16px",
    padding: "35px 45px",
  },

  "& .cancel-button": {
    width: "44px",
    height: "44px",
    borderRadius: "8px",
    background: "#EEEEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    right: 10,
    top: 10,
  },
});
