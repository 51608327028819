import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  RegisterOptions,
} from "react-hook-form";

import TimePicker from "../TimePicker/TimePicker";

interface IDateTimeInput<FormNames extends Record<string, any>> {
  name: FieldPath<FormNames>;
  minDate?: any; //
  onChange?: (value: any) => void;
  control: Control<FormNames, any>;
  errors?: FieldErrors<any>;
  rules?: Omit<
    RegisterOptions<any, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  label?: string;
}

const DateTimeInput = <FormNames extends Record<string, any>>({
  name,
  control,
  rules = {
    required: false,
  },
  // fullWidth = false,
  errors = undefined,
  ...props
}: IDateTimeInput<FormNames>) => {
  const handleChange = (hookFormChangeHandler: any) => (value: any) => {
    hookFormChangeHandler(value);
    if (props?.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field }) => (
        <TimePicker
          id={name}
          {...props}
          {...field}
          onChange={handleChange(field?.onChange)}
          error={errors?.[name]}
        />
      )}
    />
  );
};

export default DateTimeInput;
