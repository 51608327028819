import { Grid } from "@mui/material";

import { LoginPageStyle } from "./Login.styled";
import LogoIcon from "../assets/icon/LogoIcon";
import { LoginForm } from "../components";
//@ts-ignore
import LoginImage from "../assets/loginImage.svg";

const Login = () => {
  return (
    <LoginPageStyle>
      <Grid container>
        <Grid item md={5} className="loginPageLeft">
          <LogoIcon />
          <LoginForm />
        </Grid>
        <Grid
          item
          md={7}
          className="loginPageRight d-flex align-items-center justify-content-center"
        >
          <img src={LoginImage} alt="Login_page_image" />
        </Grid>
      </Grid>
    </LoginPageStyle>
  );
};

export default Login;
