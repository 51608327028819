import styled from "styled-components";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";

export const TableWrapperStyled = styled.div`
  position: relative;
  .normalize-table {
    width: 100%;
    height: 54px;
  }
`;

export const TableContainerStyled = styled(TableContainer)<any>`
  max-height: calc(100vh - 188px - ${({ height }: any) => height + "px"});
  min-height: calc(100vh - 188px - ${({ height }: any) => height + "px"});
  background-color: #fff;
`;

export const StyledTable = styled(Table)`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.bg.whiteBg};
  border-collapse: inherit !important;

  .loader-body {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 30;
    td {
      border-bottom: none;
    }
  }
`;

export const TableCellStyled = styled(TableCell)`
  color: ${({ theme }) => theme.colors.textColor};
  font-family: "Ubuntu", "Arial", sans-serif !important;
  font-weight: normal !important;
`;

export const TableBodyStyled = styled(TableBody)<any>`
  row-gap: 0;
  border: none !important;
  overflow-y: auto;
  td {
    font-family: "Ubuntu", "Arial", sans-serif !important;
  }
  .item.row-hover:hover {
    cursor: pointer;
    background-color: #88888844;
  }
  .columns {
    transition: all 0.3s;
    border-collapse: inherit !important;
    border-width: 0 !important;
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:last-child {
      text-align: end;
    }
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
      color: ${({ theme }) => theme.colors.text.lightBlack} !important ;
    }
  }
`;

export const TableCellCheckbox = styled(TableCell)`
  width: 50px !important;
`;
