import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import browserStorage from "services/storage/browserStorage";

import { Typography } from "@mui/material";
import { CommonContext } from "context";
import { REQUEST_STATUS } from "hooks";
import { ChangeLanguageStyled } from "./ChangeLanguage.styles";

const ChangeLanguage = () => {
  const {
    state: {
      getDefaultPhoneState: { getDefaultPhoneData, getDefaultPhoneStatus },
    },
  } = useContext(CommonContext);

  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (language: string) => () => {
    //! @here  fix this code
    i18n.changeLanguage(language);

    handleClose();
  };

  return (
    <ChangeLanguageStyled>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {browserStorage.get("i18nextLng") === "kk"
          ? "kz"
          : browserStorage.get("i18nextLng")}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: -4,
        }}
      >
        {getDefaultPhoneStatus === REQUEST_STATUS.loading ? (
          <MenuItem>
            <Typography>Loading...</Typography>
          </MenuItem>
        ) : (
          getDefaultPhoneStatus === REQUEST_STATUS.success &&
          getDefaultPhoneData?.data?.languages?.map((language: string) => (
            <MenuItem onClick={handleChangeLanguage(language)} key={language}>
              <Typography textTransform="uppercase">
                {language === "kk" ? "kz" : language}
              </Typography>
            </MenuItem>
          ))
        )}
      </Menu>
    </ChangeLanguageStyled>
  );
};

export default ChangeLanguage;
